import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { Select, MenuItem, OutlinedInput, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Dropdown = forwardRef(
  (
    {
      name,
      label,
      defaultValue,
      options,
      value,
      defaultOption,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const theme = useTheme();
    const isMounted = useRef(false);

    useEffect(() => {
      if (!isMounted.current && defaultOption && options && options?.length > 0) {
        const selectedValues = options?.filter((item) => item.checked)
          .map((filterItem) => filterItem.value);
        setSelectedOptions(selectedValues);
        isMounted.current = false;
      }
    }, [defaultOption, options]);

  
    useEffect(() => {
      if (isMounted.current) {
        value(name, selectedOptions);
      }
    }, [selectedOptions]);

    const handleChangeMultiSelect = (event) => {
      let selectedPageOptions = []
      event.target.value.map(val => {
        options.map(op => {
             if(op.value == val){
              selectedPageOptions.push(val)
             }
        })
      })
      value(name, selectedPageOptions);
      setSelectedOptions(selectedPageOptions)
      // setSelectedOptions(event.target.value);
    };

    function getStyles(name, personName, theme) {
      return {
        fontWeight:
          personName?.indexOf(name) === -1
            ? theme?.typography.fontWeightRegular
            : theme?.typography.fontWeightMedium,
      };
    }

    const renderSelectedOptions = (selected) => {
      if (options?.length === 0) return '';

      return selected
        .map((value) => {
          const option = options?.find((option) => option.value === value);
          return option ? option.label : '';
        })
        .filter(Boolean) // Filter out falsy values (e.g., undefined)
        .join(', ');
    };

    const multiSelect = () => {
      return (
        <>
          <Select
            disabled={disabled}
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedOptions}
            onChange={handleChangeMultiSelect}
            input={<OutlinedInput label={label} />}
            renderValue={renderSelectedOptions}
            MenuProps={MenuProps}
            placeholder='Select Pages'
          >
            {options &&
              options?.map((name) => (
                <MenuItem key={name.value} value={name.value} disabled={name?.used_mode}>
                  <FormControlLabel
                    control={<Checkbox checked={selectedOptions.indexOf(name.value) > -1} />}
                    label={
                      <label htmlFor={name.value}>
                        {name.label}
                       { name?.path && <span className='ml-2 mx-2'>{`Slug - (${name?.path}) `}</span>}
                       { name?.campaign_name && <span className='ml-2 '>{`Campaign - (${name?.campaign_name})`}</span>}
                      </label>
                    }
                  />
                </MenuItem>
              ))}
          </Select>
        </>
      );
    };

    return <div className="mui_lable_hidden">{multiSelect()}</div>;
  }
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
