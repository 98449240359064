import axios from 'axios';
import { toast } from "react-toastify";
import tokenManager from './tokenManager';

let isUnauthorizedAlertShown = false;

const axiosInstance = axios.create({

    baseURL: "https://bridge.flexifunnels.com/api",
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log("error",error)
    const status = error.response ? error.response.status : null;
    if (status === 401) {
        if (!isUnauthorizedAlertShown) {
            toast.error("Unauthorized user");
            tokenManager.removeToken();
            isUnauthorizedAlertShown = true;
        }
       
    } else if (status === 404) {
        toast.error(error.message)
    } else {
        // if(error.code == "ERR_NETWORK"){
        //     toast.error(error.message)
        // } else {
            toast.error(error.message)
            // tokenManager.removeToken();
            // isUnauthorizedAlertShown = true;
        // }
        
    }
    return Promise.reject(error);
});

export default axiosInstance;