
// import axios from "axios";
import ApiService from "./ApiService";
import axios from '../utility/interceptor';

class PageService {
    
    getPages(formData) {
        return axios.post(`${ApiService.Page}/page-list`, formData)
    }

    getPageSettings(formData) {
        return axios.post(`${ApiService.Page}/campaign-page-settings`, formData)
    }

    getAlertSettings(formData) {
        return axios.post(`${ApiService.Campaign}/campaign-alert-settings`, formData)
    }

    getAlertSettingById(formData) {
        return axios.post(`${ApiService.Campaign}/campaign-alert-settings-by-id`, formData)
    }
    


    saveAlertSetting(formData) {
        return axios.post(`${ApiService.Campaign}/save-alert-setting`, formData) 
    }

    updateAlertSetting(formData) {
        return axios.post(`${ApiService.Campaign}/save-alert-setting`, formData)
    }

    getProofAlert(formData) {
        return axios.post(`${ApiService.Campaign}/api/get-proof-alert`, formData)
    }

    savePageSetting(formData) {
        return axios.post(`${ApiService.Campaign}/save-page-setting`, formData) 
    }


}

export default new PageService();