import { EventEmitter } from 'events';

class TokenManager extends EventEmitter {
  constructor() {
    super();
  }

  removeToken() {
    this.emit('tokenRemoved');
  }
}

const tokenManager = new TokenManager();

export default tokenManager;