export default function CofirmModal({
    shownPopup,
    closePopup,
    title,
    subTitle,
    type,
    removeAction
}) {

    return <>
        <div className={shownPopup ? `fp-delete-modal` : `fp-delete-modal display_model`}>
            <div className="modal-dialog modal-two">
                <div className="fp_modal_content">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => closePopup()} ></button>
                        </div>
                        <div className="fp_model_user fp_model_delete">
                            <img src="https://img.flexifunnels.com/flexiproof-assets/delete_img.png" alt="Delete icon" />
                            <h3>Delete Confirmation</h3>
                            <p>Are you sure you want to delete this campaign?</p>
                            <div className="fp_modal_footer">
                                <button type="button" className="fp-btn fp-btn-dark" onClick={() => closePopup()}>No, cancel it!</button>
                                <button type="button" className="fp-btn fp-btn-danger" onClick={() => removeAction()}>Yes, delete it!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}