
// import axios from "axios";
import ApiService from "./ApiService";
import axios from '../utility/interceptor';

class ProductService {
    getProducts(formData) {
        return axios.post(`${ApiService.Project}/product-list`, formData)
    }
}

export default new ProductService();