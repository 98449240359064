import {Helmet} from "react-helmet";
import svg from "../components/svg";
import Header from "../components/Header"; 
import CampaingsMain from "../components/Campaings"; 
import { useEffect } from "react";
import CampaignService from "../service/CampaignService";



const Campaigns = ({pageTitle}) => {

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            {/* <Header/> */}
            <CampaingsMain />
        </>
    )
};

export default Campaigns;