import React, { useState } from 'react';
import Select from 'react-select';

export default function AutoCompleteDropDown({options, defaultValue, onChangeCallBAck}) {
  const handleOnChange = (value) => {
    onChangeCallBAck(value.value)
  }

  return (
    <div className="App">
      <Select
        key={defaultValue}
        defaultValue={options?.find(option => option.value === defaultValue)}
        onChange={handleOnChange}
        options={options}
        placeholder="Select Widget Position"
      />
    </div>
  );
}