import { useEffect, useState } from "react";

export default function Pagination(props) {
    const [paging, setPaging] = useState({
        page: 1,
        noOfPages: 0,
        limit : 10,
        fromStart : 1,
        perSlidePage : 3,
        // lastPage: 3,
        pageNumbers :[]
    })

    useEffect(() => {
        if (!props?.paging?.noOfPages) return
        paging.page = props?.paging?.page
        paging.noOfPages = props?.paging?.noOfPages
        setPaging({ ...paging })
        calculatePage(paging)
        // setPaging({ ...paging, page: props?.paging?.page, noOfPages: props?.paging?.noOfPages, lastPage:props?.paging?.page+4 })
    }, [props?.paging])

    const onPrev = () => {
        if (paging.page === 1) return
        setPaging({ ...paging, page: paging.page - 1 })
        props.onClick && props.onClick(paging.page - 1);
    }

    const onNext = () => {
        if (paging.page === props.paging.noOfPages) return
        setPaging({ ...paging, page: paging.page + 1 })
        props.onClick && props.onClick(paging.page + 1);
    }

    const updatePage = (page) => {
        if (page == paging.page) return
        setPaging({ ...paging, page: page })
        props.onClick && props.onClick(page);
    }

    const calculatePage = () => {
        let pageNumbers = []
        let startingPage = paging.fromStart
        let endingPage = startingPage + paging.perSlidePage-1

        if (endingPage > paging.noOfPages) {
            endingPage = paging.noOfPages
        }
        for (let i = startingPage; i <= endingPage; i++) {
            pageNumbers.push(i);
        }
        paging.pageNumbers = pageNumbers
        setPaging({ ...paging })
        // console.log(paging)
    }

    const onNextSlide = () => {
        if ( paging.fromStart+paging.perSlidePage > props.paging.noOfPages) return
        setPaging({ ...paging, page: paging.page + 1, fromStart: paging.fromStart + paging.perSlidePage })
        props.onClick && props.onClick(paging.fromStart + paging.perSlidePage);
    }

    const onPrevSlide = () => {
        if (paging.fromStart == 1) return
        setPaging({ ...paging, page: paging.page - paging.perSlidePage,fromStart: paging.fromStart - paging.perSlidePage })
        props.onClick && props.onClick(paging.page - paging.perSlidePage);
    }

    return (
        <div className="fp-campaigns-foot">
            <div className="fp-pagination">
                Page {paging.page + " of " + paging.noOfPages}

                <div className="fp-nex-prev-data-btn">                    

                    <a href="#" className={ paging.fromStart == 1 ? "fp-prev-page fp-disabled" : "fp-prev-page"}onClick={() => onPrevSlide()}>
                        <img src="https://img.flexifunnels.com/flexiproof-assets/next-arrow.svg" alt="" />
                    </a>

                    {paging.pageNumbers.map((page,index) =>{
                        return <span key={index} className={`fp_pagination ${page == paging.page ? "active" : ""}`} onClick={() => updatePage(page)}>{page}</span>
                    })}
                    
                    <a href="#"  className={ paging.fromStart+paging.perSlidePage > props.paging.noOfPages ? "fp-next-page fp-disabled" : "fp-next-page"} onClick={() => onNextSlide()}>
                        <img src="https://img.flexifunnels.com/flexiproof-assets/next-arrow.svg" alt="" />
                    </a>                    
                </div>
            </div>
        </div>
    )
}