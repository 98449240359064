import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

const Option = (props) => {
  const { data, isDisabled } = props;
  const checked = data.checked;
  const backgroundColor = checked ? 'yellow' : isDisabled ? 'red' : 'red';

  const optionClassName = checked ? 'optionClassName' : '';

  return (
    <components.Option {...props} className={optionClassName} />
  );
};

export default ({ options, defaultValue, onChangeCallBAck }) => {

  const handleOnchangeEvent = (value) => {
    onChangeCallBAck(value.value)
  }
  const Option = (props) => {
    const { data, isDisabled } = props;

    const checked = data.checked;
    const backgroundColor = checked ? 'yellow' : isDisabled ? 'red' : 'red';

    const optionClassName = checked ? 'optionClassName' : '';
    return (
      <components.Option {...props} className={optionClassName}>
        <div className='fp_dropdown_check_box '>
        <label>{props.label}</label>
          {checked && <div className="fp_dropdown_check_boxround">
            <input type="checkbox" checked={checked} onChange={() => null} />
            <label for="checkbox"></label>
          </div>}
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      key={defaultValue}
      closeMenuOnSelect={true}
      components={{ Option }}
      defaultValue={options?.find(option => option.value == defaultValue)}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      getOptionIsDisabled={(option) => option.isDisabled}
      onChange={handleOnchangeEvent}
    />
  );
};
