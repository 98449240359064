import AuthService from "../service/AuthService"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../redux/slices/commonSlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";


export default function Login() {
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        // const code = params.get('code');
        // handleLogin(code)
    }, [])

    const handleLogin = (loginCode) => {
        if (!loginCode) {
            setLoading(true)
            return
        }
        AuthService.login(loginCode).then(res => {
            if (res.status == 200) {
                let obj = {
                    user: res.data?.User,
                    token: res.data?.access_token
                }
                dispatch(setUserDetails(obj))
                navigate('/campaigns');
            } else {
                toast.error("error")
            }
        }).catch(error => {
            toast.error("error")
            if (error.response.status == 401) {
            } else if (error.code === "ERR_NETWORK") {
            } else {
            }


        });
    }


    return (
        <>

            <div className="fp-auth-wrapper">
                <div className="fp-auth-logo">
                    <img src="https://img.flexifunnels.com/flexiproof-assets/auth-logo.svg" alt="FlexiProof" />
                </div>
                <div className="fp-auth-container">
                    <div className="fp-auth-row">
                        <div className="fp-auth-left">
                            <img src="https://img.flexifunnels.com/flexiproof-assets/login-shape.png" alt="vector" />
                            <div className="fp-login-logo">
                                <img src="https://img.flexifunnels.com/flexiproof-assets/loginlogo1.svg" alt="vector" />
                            </div>
                            <h4> Build Website & Funnels <br /> In Minutes.</h4>
                        </div>
                        {/* loginImage  */}
                        <div className="fp-auth-right">
                            <div className="fp-welcome-title">
                                <h2>Instructions To Login</h2>
                            </div>
                            <div className="fp-welcome-content">
                                <div className="fp-auth-step">
                                    <h4>step1</h4>
                                    <img src="https://img.flexifunnels.com/flexiproof-assets/auth-step-icon.svg" alt="step icon" />
                                    <p>Please login to your Flexifunnels dashboard.</p>
                                </div>
                                <div className="fp-auth-step">
                                    <h4>step2</h4>
                                    <img src="https://img.flexifunnels.com/flexiproof-assets/auth-step-icon.svg" alt="step icon" />
                                    <p>Select the "Flexiproof" option from the menu under your profile. This will automatically login to your Flexiproof account.</p>
                                </div>
                            </div>
                            <div className="fp-auth-btn">
                                <a href="https://app.flexifunnels.com/"> Login to Flexifunnels</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}