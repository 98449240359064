import ApiService from "./ApiService";
// import axios from "axios";
import axios from '../utility/interceptor';

class AuthService {
    login(formData) {
        return axios.post(`${ApiService.Login}?code=${formData}`)
    }

    Logout(formData) {
        return axios.post(`${ApiService.Login}/logout=${formData}`)
    }
}

export default new AuthService();