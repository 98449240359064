import { useEffect, useState } from "react";
import AlertType from "./AlertType";
import { AlertTemplates, AlertTypeList } from "../utility/constant";
import { useSelector } from "react-redux";
import PageService from "../service/PageService";
import { toast } from "react-toastify";

export default function AlertSetting(props) {
    const [changeTab, setchangeTab] = useState("")
    const [alertTypes, setAlertTypes] = useState(AlertTypeList)
    const [selectedAlertTypes, setSelectedAlertTypes] = useState([])
    const [selectedAlertType, setSelectedAlertType] = useState({})
    const [products, setProducts] = useState([])
    const [pages, setPages] = useState([])
    const [projects, setProjects] = useState([])

    const userDetails = useSelector((data) => data.common)
    
    useEffect(() => {
        if (!props?.changeTab1) return
        setchangeTab(props?.changeTab1)
    }, [props?.changeTab1])

    useEffect(() => {
        // console.log("changeTab",props?.camapaignDetails?.alertSettings)
        if (!props) return
        if (props?.camapaignDetails?.alertSettings) {
            setCreateAlertType(props?.camapaignDetails)
        }
        setProducts(props?.products)
        setPages(props?.pages)
        setProjects(props?.projects)
    }, [props])

    const setCreateAlertType = (camapaignDetails) => {
        let alertSettings = camapaignDetails?.alertSettings
        // console.log("alertSettings",alertSettings)
        if (alertSettings?.length == 0) return
        let alertTypeArr = []
        alertSettings?.map(alertType => {
            if (alertType.alert_type === "sale") {
                alert.checked = alertType.alert_customization.checked
                let obj = {
                    alertDesign: alertType.alert_customization,
                    products: alertType.products,
                    funnel_id: alertType?.funnel_id,
                    funnel_page_id: alertType?.funnel_page_id,
                    type: alertType.alert_type,
                    prof_campaigns_setting_id: alertType.prof_campaigns_setting_id,
                    // status : 5
                }
                alertTypeArr.push(obj)
            } else if (alertType.alert_type === "lead") {
                alert.checked = alertType.alert_customization.checked
                let obj = {
                    alertDesign: alertType.alert_customization,
                    funnel_id: alertType?.funnel_id,
                    funnel_page_id: alertType?.funnel_page_id,
                    pages: alertType.pages,
                    type: alertType.alert_type,
                    prof_campaigns_setting_id: alertType.prof_campaigns_setting_id,
                    // status : alertType.status
                }

                alertTypeArr.push(obj)
            } else if (alertType.alert_type === "visitor") {
                alert.checked = alertType.alert_customization.checked
                let obj = {
                    alertDesign: alertType.alert_customization,
                    funnel_id: alertType?.funnel_id,
                    funnel_page_id: alertType?.funnel_page_id,
                    pages: alertType.pages,
                    type: alertType.alert_type,
                    prof_campaigns_setting_id: alertType.prof_campaigns_setting_id,
                    status: alertType.status
                }
                alertTypeArr.push(obj)
            }
        })

        const updatedAlertTypes = AlertTypeList.map(type => {
            const matchingAlert = alertTypeArr.find(alertType => alertType.type === type.value);
            if (matchingAlert) {
                return {
                    ...type,
                    alertSettings: matchingAlert,
                    checked: matchingAlert.alertDesign.checked
                };
            } else {
                return type;
            }
        });
        // console.log("updatedAlertTypes",updatedAlertTypes)
        setAlertTypes(updatedAlertTypes)
        setSelectedAlertTypes(updatedAlertTypes)
    }

    const updateAlertSetting1 = (formData) => {
        return new Promise((resolve, reject) => {
            PageService.saveAlertSetting(formData).then((resp) => {
                if (resp.data.success) {
                    toast.success(resp.data.message)
                    resolve(resp.data.alert_setttings_details)
                } else {
                    reject(new Error("Failed to save alert"));
                    toast.error(resp.data.message)
                }
            }).catch(error => {

            });;
        })
    };

    const handleAlertSwitch = (index, status) => {
        const updatedAlertTypes = [...alertTypes];
        updatedAlertTypes[index] = {
            ...updatedAlertTypes[index],
            checked: !status
        };
        if (selectedAlertTypes.length === 0) {
            let selectedType = updatedAlertTypes.find(item => item.checked)
            setSelectedAlertType({ ...selectedType });
            setSelectedAlertTypes(updatedAlertTypes);
        } else {
            const updatedSelectedAlertTypes = selectedAlertTypes.map(type => {
                const matchingAlertType = updatedAlertTypes.find(alertType => alertType.value === type.value);
                if (matchingAlertType) {
                    return {
                        ...matchingAlertType,
                        checked: matchingAlertType.checked
                    };
                }
                return type;
            });

            if (updatedSelectedAlertTypes[index]?.alertSettings?.type === "lead" || updatedSelectedAlertTypes[index]?.alertSettings?.type === "visitor") {
                updateLeadAlertStatus(updatedSelectedAlertTypes[index].alertSettings, status)
            } else if (updatedSelectedAlertTypes[index]?.alertSettings?.type === "sale") {
                updateSaleAlertStatus(updatedSelectedAlertTypes[index].alertSettings, status)
            }

            let selectedType = updatedSelectedAlertTypes[index]
            setSelectedAlertType({ ...selectedType });
            setSelectedAlertTypes(updatedSelectedAlertTypes);
        }

        setAlertTypes(updatedAlertTypes);
    }

    const handleAlertSetting = (data) => {
        setchangeTab(data)
        setSelectedAlertType(data)
    }

    const getSelectedProduct = (products) => {
        let seletedIds = [];
        products?.map((product) => {
            if (product.checked) {
                seletedIds.push(product.product_id);
            }
        });
        return seletedIds;
    };

    const getSelectedpages = (pages) => {
        let seletedIds = [];
        pages?.map((page) => {
            if (page.checked) {
                seletedIds.push(page.funnel_page_id);
            }
        });
        return seletedIds;
    };

    const prepareSalesAlertData = (saleAlertData) => {
        let alertData = {
            user_id: userDetails.user.id,
            prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
            alert_type: saleAlertData.alertType,
            product_id: JSON.stringify(getSelectedProduct(saleAlertData.products)),
            funnel_id: saleAlertData?.funnel_id,
            funnel_page_id: JSON.stringify(saleAlertData.pages),
            alert_customization: JSON.stringify(saleAlertData.alertDesign),
            token: userDetails.token,
            prof_campaigns_setting_id: saleAlertData?.prof_campaigns_setting_id,
        }
        props?.selectedAlertType(alertData)
        return alertData
    }

    const prepareLeadsAlertData = (leadAlertData) => {
        let alertData = {
            user_id: userDetails.user.id,
            prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
            prof_campaigns_setting_id: leadAlertData?.prof_campaigns_setting_id,
            alert_type: leadAlertData.alertType,
            funnel_id: JSON.stringify(leadAlertData.funnel_id),
            // funnel_id: "[95105, 95039, 94944]",
            // funnel_page_id: "[484955,484956,484593,484594,484074,484073]",
            funnel_page_id: JSON.stringify(getSelectedpages(leadAlertData.pages)),
            alert_customization: JSON.stringify(leadAlertData.alertDesign),
            token: userDetails.token,
        }
        props?.selectedAlertType(alertData)
        return alertData
    }

    const handleAlertSave = (data) => {
        if (data.alertType === "sale") {
            if (data.isSave) {
                updateAlertSetting1(prepareSalesAlertData(data))
                    .then(updateSalesData => {
                        if (updateSalesData) {
                            let salesAlertData = {
                                funnel_id: data?.funnel_id,
                                funnel_page_id: data?.pages,
                                products: data.products,
                                alertDesign: data.alertDesign,
                                prof_campaigns_setting_id: updateSalesData.prof_campaigns_setting_id,
                                prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
                                status:updateSalesData?.status || 1,
                            };
                            let salesAlertIndex = selectedAlertTypes?.findIndex(type => type.name === "Sales Alert");
                            selectedAlertTypes[salesAlertIndex]['alertSettings'] = salesAlertData;
                            setSelectedAlertTypes([...selectedAlertTypes]);
                            setSelectedAlertType({ ...selectedAlertType, alertSettings: salesAlertData });
                            if(data.isBehaviour){
                                props?.changeTab(salesAlertData)
                            }
                        }
                    })
                    .catch(error => {
                        // Handle errors
                    });
            } else {
                let salesAlertData = {
                    funnel_id: data?.funnel_id,
                    funnel_page_id: data?.pages,
                    products: data.products,
                    alertDesign: data.alertDesign,
                    prof_campaigns_setting_id: data?.prof_campaigns_setting_id,
                    prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
                }

                let salesAlertIndex = selectedAlertTypes?.findIndex(type => type.name === "Sales Alert")
                selectedAlertTypes[salesAlertIndex]['alertSettings'] = salesAlertData
                setSelectedAlertTypes([...selectedAlertTypes])
                setSelectedAlertType({ ...selectedAlertType, alertSettings: salesAlertData })
            }
        } else if (data.alertType === "lead") {
            if (data.isSave) {
                updateAlertSetting1(prepareLeadsAlertData(data))
                    .then(updateSalesData => {
                        if (updateSalesData) {
                            let leadsAlertData = {
                                pages: data.pages,
                                funnel_id: data.funnel_id,
                                funnel_page_id: data.funnel_page_id,
                                alertDesign: data.alertDesign,
                                prof_campaigns_setting_id: updateSalesData.prof_campaigns_setting_id,
                                prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
                                status:updateSalesData.status
                            }
                            let salesAlertIndex = selectedAlertTypes?.findIndex(type => type.name === "Leads Alert")
                            selectedAlertTypes[salesAlertIndex]['alertSettings'] = leadsAlertData
                            setSelectedAlertTypes([...selectedAlertTypes])
                            setSelectedAlertType({ ...selectedAlertType, alertSettings: leadsAlertData })
                            if(data.isBehaviour){
                                props?.changeTab(leadsAlertData)
                            }
                        }
                    })
                    .catch(error => {
                        // Handle errors
                    });
            } else {
                let leadsAlertData = {
                    pages: data.pages,
                    funnel_id: data.funnel_id,
                    funnel_page_id: data.funnel_page_id,
                    alertDesign: data.alertDesign,
                    prof_campaigns_setting_id: data?.prof_campaigns_setting_id,
                    prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
                   
                }
                let salesAlertIndex = selectedAlertTypes?.findIndex(type => type.name === "Leads Alert")
                selectedAlertTypes[salesAlertIndex]['alertSettings'] = leadsAlertData
                setSelectedAlertTypes([...selectedAlertTypes])
                setSelectedAlertType({ ...selectedAlertType, alertSettings: leadsAlertData })
            }
        } else if (data.alertType === "visitor") {

            if (data.isSave) {
                updateAlertSetting1(prepareLeadsAlertData(data))
                    .then(updateSalesData => {
                        if (updateSalesData) {
                            // console.log("data",data)
                            // console.log("updateSalesData",updateSalesData)
                            let visitorAlertData = {
                                pages: data.pages,
                                funnel_id: data.funnel_id,
                                funnel_page_id: data.funnel_page_id,
                                alertDesign: data.alertDesign,
                                prof_campaigns_setting_id: updateSalesData.prof_campaigns_setting_id,
                                prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
                                status:updateSalesData.status
                            }

                            let visitorAlertIndex = selectedAlertTypes?.findIndex(type => type.name === "Visitor Alert")
                            selectedAlertTypes[visitorAlertIndex]['alertSettings'] = visitorAlertData
                            setSelectedAlertTypes([...selectedAlertTypes])
                            setSelectedAlertType({ ...selectedAlertType, alertSettings: visitorAlertData })
                            if(data.isBehaviour){
                                props?.changeTab(visitorAlertData)
                            }
                        }
                    })
                    .catch(error => {
                        // Handle errors
                    });
            } else {
                let visitorAlertData = {
                    pages: data.pages,
                    funnel_id: data.funnel_id,
                    funnel_page_id: data?.funnel_page_id,
                    alertDesign: data.alertDesign,
                    prof_campaigns_setting_id: data?.prof_campaigns_setting_id,
                    prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
                  
                }
                let visitorAlertIndex = selectedAlertTypes?.findIndex(type => type.name === "Visitor Alert")
                selectedAlertTypes[visitorAlertIndex]['alertSettings'] = visitorAlertData
                setSelectedAlertTypes([...selectedAlertTypes])
                setSelectedAlertType({ ...selectedAlertType, alertSettings: visitorAlertData })
            }
        }
    }

    const showSelectedAlertType = (type) => {
        if (selectedAlertType?.name === type && selectedAlertType.checked) {
            return "alerttype-active"
        } else {
            return ""
        }
    }

    const handleTabChange = (data) => {
        selectedAlertTypes.map(item => {
            if (item.value === data.value) {
                item.checked = true
            }
        })
        // console.log("selectedAlertTypes",selectedAlertTypes)
        // console.log("selectedAlertType",selectedAlertType)
        setSelectedAlertTypes([...selectedAlertTypes])
    }

    const updateLeadAlertStatus = (alertData, status) => {
        alertData.alertDesign.checked = !status
        let updatedAlertData = {
            user_id: userDetails.user.id,
            prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
            prof_campaigns_setting_id: alertData?.prof_campaigns_setting_id,
            alert_type: alertData.type,
            funnel_id: alertData.funnel_id,
            funnel_page_id: JSON.stringify(getSelectedpages(alertData.pages)),
            alert_customization: JSON.stringify(alertData.alertDesign),
            token: userDetails.token,
            status: status ? 0 : 1
        }
        PageService.saveAlertSetting(updatedAlertData).then((resp) => {
            if (resp.data.success) {
                if (status) {
                    toast.success(`${alertData.type.charAt(0).toUpperCase() + alertData.type.substring(1)} alert disabled `)
                } else {
                    toast.success(`${alertData.type.charAt(0).toUpperCase() + alertData.type.substring(1)} alert enabled `)
                }
            } else {
                toast.error(resp.data.message)
            }
        }).catch(error => {

        });;
    }

    const updateSaleAlertStatus = (alertData, status) => {
        alertData.alertDesign.checked = !status
        let updatedAlertData = {
            user_id: userDetails.user.id,
            prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
            alert_type: alertData.type,
            product_id: JSON.stringify(getSelectedProduct(alertData.products)),
            funnel_id: alertData?.funnel_id,
            funnel_page_id: JSON.stringify(alertData.pages),
            alert_customization: JSON.stringify(alertData.alertDesign),
            token: userDetails.token,
            prof_campaigns_setting_id: alertData?.prof_campaigns_setting_id,
            status: status ? 0 : 1
        }
        PageService.saveAlertSetting(updatedAlertData).then((resp) => {
            if (resp.data.success) {
                // console.log(resp.data)
                if (status) {
                    toast.success(`${alertData.type.charAt(0).toUpperCase() + alertData.type.substring(1)}s alert disabled `)
                } else {
                    toast.success(`${alertData.type.charAt(0).toUpperCase() + alertData.type.substring(1)}s alert enabled `)
                }
            } else {
                toast.error(resp.data.message)
            }
        }).catch(error => {

        });;
    }

    return (
        <>
            <div className="tab-main-box">
                <div className="tab-create-camp-wrapper">
                    <div className="fp-create-camp-heading">
                        <span>
                            <svg width="17px" height="17px" version="1.1" x="0" y="0" viewBox="0 0 512 512"><g><path d="M448 232.148c-11.777 0-21.332-9.554-21.332-21.332 0-59.84-23.297-116.074-65.602-158.402-8.34-8.34-8.34-21.82 0-30.164 8.34-8.34 21.825-8.34 30.164 0 50.372 50.367 78.102 117.336 78.102 188.566 0 11.778-9.555 21.332-21.332 21.332zM21.332 232.148C9.559 232.148 0 222.594 0 210.816 0 139.586 27.734 72.617 78.102 22.25c8.34-8.34 21.824-8.34 30.164 0 8.343 8.344 8.343 21.824 0 30.164C65.96 94.72 42.668 150.977 42.668 210.816c0 11.778-9.559 21.332-21.336 21.332zM434.754 360.813C402.496 333.546 384 293.695 384 251.476V192c0-75.07-55.766-137.215-128-147.625V21.332C256 9.535 246.441 0 234.668 0c-11.777 0-21.336 9.535-21.336 21.332v23.043c-72.254 10.41-128 72.555-128 147.625v59.477c0 42.218-18.496 82.07-50.941 109.503a37.262 37.262 0 0 0-13.059 28.352c0 20.59 16.746 37.336 37.336 37.336h352c20.586 0 37.332-16.746 37.332-37.336 0-10.922-4.758-21.246-13.246-28.52zM234.668 512c38.633 0 70.953-27.543 78.379-64H156.289c7.422 36.457 39.742 64 78.379 64zm0 0" opacity="1" /></g>
                            </svg>
                        </span>
                        <h4>Alert Settings</h4>
                    </div>
                    <div className="fp-create-camp-body">
                        <div className="fp-frm-wrapper">
                            <h4 className="fp-subtitle">
                                Choose Alert Types
                                <div className="info-tooltip">
                                    <img src="https://img.flexifunnels.com/flexiproof-assets/info.svg" alt="" />
                                    <span className="data">
                                        To choose alert click on the toggle button
                                    </span>
                                </div>
                            </h4>

                            <div className="fp-alert-selection-wrap">
                                {alertTypes?.map((alertData, index) => {
                                    return <div key={index} className={`fp-alert-selection-box ${showSelectedAlertType(alertData.name)}`} style={{ cursor: "pointer" }} onClick={() => handleAlertSetting(alertData)} >
                                        <div className="fp-alert-selection-head" >
                                            <h4>{alertData?.icon}{alertData?.name == "Visitor Alert" ? "Visitors Alert" : alertData?.name}</h4>
                                            <div className="fp-widget-select-action">
                                                <div className="fp-switch-label">
                                                    <input type="checkbox" id={alertData?.name} checked={alertData?.checked} onChange={() => handleAlertSwitch(index, alertData?.checked)} />
                                                    <label htmlFor={alertData?.name}></label>
                                                </div>
                                            </div>
                                        </div>
                                        <p>{alertData?.content}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedAlertType?.checked && <AlertType
                changeTab={changeTab}
                templates={AlertTemplates}
                products={products}
                pages={pages}
                projects={projects}
                alertType={selectedAlertType}
                handleSaveAlert={handleAlertSave}
                updateTab={handleTabChange}
            />}
        </>
    )
}