"use client";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect } from "react";


export function Providers({ children }) {

  // useEffect(() => {
  //   const handleUnload = () => {
  //     // Clear the persisted state when the application is closed
  //     persistor.purge();
  //   };

  //   window.addEventListener('beforeunload', handleUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleUnload);
  //   };
  // }, []);

  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
}
