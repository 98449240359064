import svg from "../components/svg"

export const AlertTypeList = [{
    icon: svg.app.SalesIcon,
    name: "Sales Alert",
    value: "sale",
    content: "The sales alert feature shows visitors recent activities like purchases, helping them stay engaged and see how popular the product is. This increased engagement and awareness can help improve the conversion rate of your landing page by building trust and urgency.",
    checked: false
}, {
    icon: svg.app.LeadsIcon,
    name: "Leads Alert",
    value: "lead",
    content: "The lead alert feature keeps visitors updated on recent activities, like new leads, which boosts their engagement and helps them see how popular the product is. This can improve the conversion rate of your landing page by building trust and urgency.",
    checked: false
}, {
    icon: svg.app.VisitorIcon,
    name: "Visitor Alert",
    value: "visitor",
    content: "The visitor alert feature keeps visitors updated on real-time activities happening on your site, enhancing their engagement and understanding of the product's popularity.",
    checked: false
}]

export const AlertTypeDesignSetting = [
    { name: "Bold", value: "isBold", icon: svg.app.boldIcon, class: "fp-widget-title-weight", checked: false },
    { name: "Italic", value: "isItalic", icon: svg.app.italicIcon, class: "fp-widget-title-italic", checked: false },
    { name: "Underline", value: "isUnderLine", icon: svg.app.underlineIcon, class: "fp-widget-title-underlined", checked: false },
    { name: "Highlight", value: "isHighlight", class: "fp-widget-title-highlite", checked: false }
]

export const displayCount = [
    { name: "Today", value: 0, checked: false },
    { name: "Yesterday", value: 1, checked: false },
    { name: "Last 2 Days", value: 2, checked: false },
    { name: "Last 5 Days", value: 5, checked: false },
    { name: "Last 7 Days", value: 7, checked: false },
    { name: "Last 15 Days", value: 15, checked: false },
    { name: "Last 30 Days", value: 30, checked: false },
    { name: "All Time", value: 365, checked: false }
]

export const MobilePositions = [
    { label: "Top", value: "TopLeft", checked: false },
    { label: "Bottom", value: "BottomLeft", checked: false },
    // { name: "Center", value: "TopRight", checked: false },

]

export const HighlightColors = [
    { value: "#222", label: "nonecolor" },
    { value: "#F4F4F4", label: "color1" },
    { value: "#FF3A3A", label: "color2" },
    { value: "#FBC640", label: "color3" },
    { value: "#BFFB40", label: "color4" },
    { value: "#40FB5E", label: "color5" },
    { value: "#18DB95", label: "color6" },
    { value: "#FB40E8", label: "color7" },
    { value: "#A840FB", label: "color8" },
    { value: "#2E2E2E", label: "color9" },
    { value: "#FB406D", label: "color10" },
    { value: "#4340FB", label: "color11" }
]

export const Gradientcolors = [
    { value: "nonecolor", label: "nonecolor", colorValue: "#222" },
    { value: "gradientcolor11", label: "gradientcolor1", colorValue: "linear-gradient(180deg, RGB(53, 203, 203) 0%, rgba(142,142,142,1) 100%)" },
    { value: "gradientcolor2", label: "gradientcolor2", colorValue: "linear-gradient(180deg, rgba(255, 58, 58, 1)  0%, rgba(153, 35, 35, 1) 100%)" },
    { value: "gradientcolor3", label: "gradientcolor3", colorValue: "linear-gradient(180deg, rgba(251, 198, 64, 1)  0%, rgba(149, 118, 38, 1) 100%)" },
    { value: "gradientcolor4", label: "gradientcolor4", colorValue: "linear-gradient(180deg, rgba(191, 251, 64, 1)  0%, rgba(113, 149, 38, 1) 100%)" },
    { value: "gradientcolor5", label: "gradientcolor5", colorValue: "linear-gradient(180deg, rgba(64, 251, 94, 1)  0%, rgba(0, 166, 27, 1) 100%)" },
    { value: "gradientcolor6", label: "gradientcolor6", colorValue: "linear-gradient(180deg, rgba(98, 236, 186, 1)  0%, rgba(5, 183, 119, 1) 100%)" },
    { value: "gradientcolor7", label: "gradientcolor7", colorValue: "linear-gradient(180deg, rgba(255, 118, 241, 1)  0%, rgba(186, 23, 170, 1) 100%)" },
    { value: "gradientcolor8", label: "gradientcolor8", colorValue: "linear-gradient(180deg, rgba(199, 128, 255, 1)  0%, rgba(124, 47, 185, 1) 100%)" },
    { value: "gradientcolor9", label: "gradientcolor9", colorValue: "linear-gradient(180deg, rgba(46, 46, 46, 1)  0%, rgba(148, 148, 148, 1) 100%)" },
    { value: "gradientcolor10", label: "gradientcolor10", colorValue: "linear-gradient(180deg, rgba(255, 102, 139, 1)  0%, rgba(200, 68, 100, 1) 100%)" },
    { value: "gradientcolor1", label: "gradientcolor11", colorValue: "linear-gradient(180deg, rgba(244, 244, 244, 1)  0%, rgba(142, 142, 142, 1) 100%)" },
]

export const AlertTemplates = [
    {
        id: 1,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox1",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "firstName",

            // shortNote : "<h>[customer_name] ([location])</h>"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 2,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox2",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            city: "Chennai",
            state: "Tamil Nadu",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "place"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 3,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox3",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 4,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox4",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 5,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox5",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 6,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox6",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 7,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox7",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 8,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox8",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: ""
    },
    {
        id: 9,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox9",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 10,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox10",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 11,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox2 fp-salesbox11 fp-gredient-common-box",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 12,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox6 fp-salesbox12 fp-gredient-common-box",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 13,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox4 fp-salesbox13 fp-gredient-common-box",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 14,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox9 fp-salesbox14 fp-gredient-common-box",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 15,
        type: "Sales Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/leads.png",
        uploadedImage: {},
        class: "fp-sales-common-box fp-salesbox8 fp-salesbox15 fp-gredient-common-box",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Purchased the [product_name]",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 16,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox1",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }

    },

    {
        id: 17,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox2",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 18,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox3",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 19,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox4",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 20,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox5",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 21,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox6",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 22,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox7",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 23,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        class: "fp-leads-common-box fp-widget-leadsbox8",
        uploadedImage: {},
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 24,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-widget-leadsbox9",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 25,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-widget-leadsbox10",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 26,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-gredient-common-box fp-widget-leadsbox1 fp-widget-leadsbox11",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 27,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-gredient-common-box fp-widget-leadsbox4 fp-widget-leadsbox12",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 28,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-gredient-common-box fp-widget-leadsbox6 fp-widget-leadsbox13",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 29,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-gredient-common-box fp-widget-leadsbox8 fp-widget-leadsbox14",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: ""
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 30,
        type: "Leads Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/template-icon.png",
        uploadedImage: {},
        class: "fp-leads-common-box fp-gredient-common-box fp-widget-leadsbox5 fp-widget-leadsbox15",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "[customer_name], [state], [country]"
        },

        alertMessage: {
            title: "Subscribed to the newsletter",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",

        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 31,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox1",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 32,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox2",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"

        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 33,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox3",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 34,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox4",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 35,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox5",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }

    },
    {
        id: 36,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox6",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 37,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox7",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 38,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox8",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 39,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox9",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page",
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 40,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-widget-visitorbox10",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 41,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-gredient-common-box fp-widget-visitorbox1 fp-widget-visitorbox11",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 42,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-gredient-common-box fp-widget-visitorbox9 fp-widget-visitorbox12",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 43,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-gredient-common-box fp-widget-visitorbox6 fp-widget-visitorbox13",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]",
            // shortNote: "Viewed this page"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 44,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-gredient-common-box fp-widget-visitorbox3 fp-widget-visitorbox14",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    },
    {
        id: 45,
        type: "Visitor Alert",
        tempImg: "https://img.flexifunnels.com/flexiproof-assets/visitor.png",
        IconImg: "https://img.flexifunnels.com/flexiproof-assets/map-icon.png",
        templatetype: "icon",
        uploadedImage: {},
        class: "fp-visitor-common-box fp-gredient-common-box fp-widget-visitorbox8 fp-widget-visitorbox15",
        alertTitle: {
            firstName: "Customer Name",
            lastName: "proof",
            place: "India",
            city: "Chennai",
            state: "Tamil Nadu",
            country: "India",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "A Visitor from, [city], [country]"
        },

        //         A Visitor from India 

        // Viewing this page right now.

        alertMessage: {
            title: "Viewed this page",
            time: "2 minutes ago",
            isBold: false,
            isItalic: false,
            isUnderLine: false,
            isHighlight: false,
            color: "",
            personalization: "",
            shortNote: "Viewed this page"
        },
        sponsoredBy: "FlexiFunnels",
        // time: "Few minutes ago",
        color: "",
        gradientColor: "",
        isBranding: false,
        showTime: false,
        isFollowSocailMedia: false,
        widgetAnimation: "",
        socialMediaUrls: {
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Pinterest: ""
        }
    }
]

export const widgetAnimations = [
    { value: "animate__zoomIn", label: "Zoom in" },
    { value: "animate__fadeIn", label: "Fade in" },
    { value: "animate__fadeInUp", label: "Fade in up" },
    { value: "animate__bounce", label: "Bounce" },
    { value: "animate__flash", label: "Flash" },
    { value: "animate__pulse", label: "Pulse" },
    { value: "animate__shakeX", label: "Shake" },
    { value: "animate__fadeInDown", label: "Fade in Down" },
    { value: "animate__fadeInLeft", label: "Fade in Left" },
    { value: "animate__zoomInDown", label: "Zoom in Down" },
    { value: "animate__rollIn", label: "Roll In" },
    { value: "animate__swing", label: "Swing" },
    { value: "animate__tada", label: "Tada" },
]

export const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isDisabled: false, checked: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: false, checked: false },
    { value: 'purple', label: 'Purple', color: '#5243AA', isDisabled: false, checked: false },
    { value: 'red', label: 'Red', color: '#FF5630', isDisabled: false, checked: false },
];


export const salesImages = [
    { id: 1, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales01.png" },
    { id: 2, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales02.png" },
    { id: 3, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales03.png" },
    { id: 4, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales04.png" },
    { id: 5, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales05.png" },
    { id: 6, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales06.png" },
    { id: 7, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales07.png" },
    { id: 8, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales08.png" },
    { id: 9, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/sales09.png" },
    { id: 10, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice1.png" },
    { id: 11, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice2.png" },
    { id: 12, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice4.png" },
    { id: 13, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice5.png" },
    { id: 14, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice7.png" },
    { id: 15, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice8.png" },
    { id: 16, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice9.png" },
    { id: 17, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice10.png" },
    { id: 18, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice11.png" },
    { id: 19, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice12.png" },
    { id: 20, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice13.png" },
    { id: 21, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice14.png" },
    { id: 22, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice15.png" },
    { id: 23, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice16.png" },
    { id: 24, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice17.png" },
    { id: 25, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice18.png" },
    { id: 26, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice19.png" },
    { id: 27, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice20.png" },
    { id: 28, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice21.png" },
    { id: 29, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice22.png" },
    { id: 30, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice23.png" },
    { id: 31, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice24.png" },
    { id: 32, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice25.png" },
    { id: 33, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice26.png" },
    { id: 34, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice27.png" },
    { id: 35, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice28.png" },
    { id: 36, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice29.png" },
    { id: 37, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice30.png" },
    { id: 38, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice31.png" },
    { id: 39, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice32.png" },
    { id: 40, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice33.png" },
    { id: 41, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice34.png" },
    { id: 42, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice35.png" },
    { id: 43, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice36.png" },
    { id: 44, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/best_discount.png" },
    { id: 45, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/big_offer.png" },
    { id: 46, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/big_offer_sale.png" },
    { id: 47, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/big_sale.png" },
    { id: 48, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/clearence_item.png" },
    { id: 49, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/discount_sale.png" },
    { id: 50, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/grand_offer.png" },
    { id: 51, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/limited_time_sale.png" },
    { id: 52, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/mega_sale.png" },
    { id: 53, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/mega_sale2.png" },
    { id: 54, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/sale_special.png" },
    { id: 55, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/img/special_offer.png" },

]

export const salesEmojis = [
    { id: 1, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/bag_sale.gif" },
    { id: 2, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/cart.gif" },
    { id: 3, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/flag_sale.gif" },
    { id: 4, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/flame.gif" },
    { id: 5, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/limited_edition.gif" },
    { id: 6, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/live_sale.gif" },
    { id: 7, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/red_cart.gif" },
    { id: 8, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/pop_out_bag_sale.gif" },
    { id: 9, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/sale_bucket.gif" },
    { id: 10, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/shop_now2.gif" },
    { id: 11, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/super_combo.gif" },
    { id: 12, type: "sales", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/sale.gif" },
]

export const LeadImages = [
    { id: 1, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_01.png" },
    { id: 2, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_02.png" },
    { id: 3, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_03.png" },
    { id: 4, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_04.png" },
    { id: 5, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_05.png" },
    { id: 6, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_06.png" },
    { id: 7, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_07.png" },
    { id: 8, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_08.png" },
    { id: 9, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_09.png" },
    { id: 10, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_10.png" },
    { id: 11, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/leads_11.png" },
    { id: 12, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice1.png" },
    { id: 13, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice2.png" },
    { id: 14, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice4.png" },
    { id: 15, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice5.png" },
    { id: 16, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice7.png" },
    { id: 17, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice8.png" },
    { id: 18, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice9.png" },
    { id: 19, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice10.png" },
    { id: 20, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice11.png" },
    { id: 21, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice12.png" },
    { id: 22, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice13.png" },
    { id: 23, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice14.png" },
    { id: 24, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice15.png" },
    { id: 25, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice16.png" },
    { id: 26, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice17.png" },
    { id: 27, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice18.png" },
    { id: 28, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice19.png" },
    { id: 29, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice20.png" },
    { id: 30, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice21.png" },
    { id: 31, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice22.png" },
    { id: 32, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice23.png" },
    { id: 33, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice24.png" },
    { id: 34, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice25.png" },
    { id: 35, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice26.png" },
    { id: 36, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice27.png" },
    { id: 37, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice28.png" },
    { id: 38, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice29.png" },
    { id: 39, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice30.png" },
    { id: 40, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice31.png" },
    { id: 41, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice32.png" },
    { id: 42, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice33.png" },
    { id: 43, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice34.png" },
    { id: 44, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice35.png" },
    { id: 45, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice36.png" },
    { id: 46, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/girl_lead.png" },
    { id: 47, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_cap_mo.png" },
    { id: 48, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_capture.png" },
    { id: 49, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_green.png" },
    { id: 50, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_pink.png" },
    { id: 51, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_promo.png" },
    { id: 52, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_purple.png" },
    { id: 53, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/lead_yellow.png" },
    { id: 54, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/subscribe_button.png" },
    { id: 55, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/subscribe_with_image.png" },
    { id: 56, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/subscribe_with_bell.png" },
    { id: 57, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/img/subscribe_with_camp.png" },

]

export const LeadIEmojis = [
    { id: 1, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/sales/gif/flame.gif" },
    { id: 2, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/camp_promo.gif" },
    { id: 3, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/campaign.gif" },
    { id: 4, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/campaign_black_white.gif" },
    { id: 5, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/emoti_funnel.gif" },
    { id: 6, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/funnel_lead.gif" },
    { id: 7, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/lead_magnet.gif" },
    { id: 8, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/magnet.gif" },
    { id: 9, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/magnet_blue.gif" },
    { id: 10, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/magnet_orange.gif" },
    { id: 11, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/magnet_red.gif" },
    { id: 12, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/magnet_with_eye.gif" },
    { id: 13, type: "leads", url: "https://img.flexifunnels.com/flexiproof-assets/leads/gif/round_funnel.gif" },
]

export const VisitorImages = [
    { id: 1, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view01.png" },
    { id: 2, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view02.png" },
    { id: 3, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view03.png" },
    { id: 4, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view04.png" },
    { id: 5, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view05.png" },
    { id: 6, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view06.png" },
    { id: 7, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view07.png" },
    { id: 8, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view08.png" },
    { id: 9, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view09.png" },
    { id: 10, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/views/view10.png" },
    { id: 11, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice1.png" },
    { id: 12, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice2.png" },
    { id: 13, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice4.png" },
    { id: 14, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice5.png" },
    { id: 15, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice7.png" },
    { id: 16, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice8.png" },
    { id: 17, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice9.png" },
    { id: 18, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice10.png" },
    { id: 19, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice11.png" },
    { id: 20, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice12.png" },
    { id: 21, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice13.png" },
    { id: 22, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice14.png" },
    { id: 23, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice15.png" },
    { id: 24, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice16.png" },
    { id: 25, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice17.png" },
    { id: 26, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/fire_icon/slice18.png" },
    { id: 27, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice19.png" },
    { id: 28, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice20.png" },
    { id: 29, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice21.png" },
    { id: 30, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice22.png" },
    { id: 31, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice23.png" },
    { id: 32, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice24.png" },
    { id: 33, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice25.png" },
    { id: 34, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice26.png" },
    { id: 35, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice27.png" },
    { id: 36, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice28.png" },
    { id: 37, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice29.png" },
    { id: 38, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice30.png" },
    { id: 39, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice31.png" },
    { id: 40, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice32.png" },
    { id: 41, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice33.png" },
    { id: 42, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice34.png" },
    { id: 43, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice35.png" },
    { id: 44, type: "visitor", url: "https://img.flexifunnels.com/flexiproof-assets/triangle/slice36.png" },
]