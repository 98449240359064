import { useEffect, useState } from "react";
import { LeadIEmojis, LeadImages, VisitorImages, salesEmojis, salesImages, testImages } from "../../utility/constant";
import { Tab, Tabs } from "react-bootstrap";
import Loader from "./Loader";

export default function Model({
    shownPopup,
    closePopup,
    title,
    subTitle,
    type,
    removeAction,
    handleUpload,
    handleLibraryImage,
    images,
    pagination,
    handleLoadMore,
    imageLoading
}) {

    const [selectedUrl, setSelectedUrl] = useState()
    const [activeTab, setActiveTab] = useState("tab1")

    useEffect(() => {
        setSelectedUrl()
        setActiveTab("tab1")
    }, [shownPopup])

    const uploadUrl = () => {
        if (!selectedUrl) {
            closePopup()
        } else {
            handleLibraryImage(selectedUrl)
        }

    }

    const handleTabSelect = (tab) => {
        setActiveTab(tab)
    }

    const showSalesImage = () => {
        return (
            <div className="tab-pane">
                <div className="fp_modal_tab_content">
                    <div className="fpImgLibrary">
                        <div className="fp_layoutScreen fp_chooseImage_layout">
                            {salesImages.map((saleImage, index) => {
                                return <label key={`salesImage_${index}`} className="layout_label" onClick={() => { setSelectedUrl(saleImage.url) }}>
                                    <img src={saleImage.url} alt="" />
                                    <input type="radio" className="select_box_radio selectedLibImg" name="choose_image_box" />
                                    <span className="fp_selected_radio"></span>
                                </label>
                            })}
                        </div>
                        {/* <div className="fpLoadMore">
                            <a className="fp-btn">Load More</a>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="fp-btn" onClick={() => uploadUrl()}>Apply</button>
                </div>
            </div>
        )
    }

    const showLeadsImage = () => {
        return (
            <div className="tab-pane">
                <div className="fp_modal_tab_content">
                    <div className="fpImgLibrary">
                        <div className="fp_layoutScreen fp_chooseImage_layout">
                            {LeadImages.map((leadImage, index) => {
                                return <label key={`leadIamge_${index}`} className="layout_label" onClick={() => { setSelectedUrl(leadImage.url) }}>
                                    <img src={leadImage.url} alt="" />
                                    <input type="radio" className="select_box_radio selectedLibImg" name="choose_image_box" />
                                    <span className="fp_selected_radio"></span>
                                </label>

                            })}
                        </div>
                        {/* <div className="fpLoadMore">
                            <a className="fp-btn">Load More</a>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="fp-btn" onClick={() => uploadUrl()}>Apply</button>
                </div>
            </div>
        )
    }

    const showSalesEmoji = () => {
        return (
            <div className="tab-pane">
                <div className="fp_modal_tab_content">
                    <div className="fpImgLibrary">
                        <div className="fp_layoutScreen fp_chooseImage_layout">
                            {salesEmojis.map((saleEmojiImage, index) => {
                                return <label key={`saleEmoji${index}`} className="layout_label" onClick={() => { setSelectedUrl(saleEmojiImage.url) }}>
                                    <img src={saleEmojiImage.url} alt="" />
                                    <input type="radio" className="select_box_radio selectedLibImg" name="choose_image_box" />
                                    <span className="fp_selected_radio"></span>
                                </label>
                            })}
                        </div>
                        {/* <div className="fpLoadMore">
                            <a className="fp-btn">Load More</a>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="fp-btn" onClick={() => uploadUrl()}>Apply</button>
                </div>
            </div>
        )
    }

    const showLeadsEmoji = () => {
        return (
            <div className="tab-pane">
                <div className="fp_modal_tab_content">
                    <div className="fpImgLibrary">
                        <div className="fp_layoutScreen fp_chooseImage_layout">
                            {LeadIEmojis.map((leadEmojiImage, index) => {
                                return <label key={`leadEmoji${index}`} className="layout_label" onClick={() => { setSelectedUrl(leadEmojiImage.url) }}>
                                    <img src={leadEmojiImage.url} alt="" />
                                    <input type="radio" className="select_box_radio selectedLibImg" name="choose_image_box" />
                                    <span className="fp_selected_radio"></span>
                                </label>
                            })}
                        </div>
                        {/* <div className="fpLoadMore">
                            <a className="fp-btn">Load More</a>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="fp-btn" onClick={() => uploadUrl()}>Apply</button>
                </div>
            </div>
        )
    }

    const showVisitorImage = () => {
        return (
            <div className="tab-pane">
                <div className="fp_modal_tab_content">
                    <div className="fpImgLibrary">
                        <div className="fp_layoutScreen fp_chooseImage_layout">
                            {VisitorImages.map((visitorImage, index) => {
                                return <label key={`saleEmoji${index}`} className="layout_label" onClick={() => { setSelectedUrl(visitorImage.url) }}>
                                    <img src={visitorImage.url} alt="" />
                                    <input type="radio" className="select_box_radio selectedLibImg" name="choose_image_box" />
                                    <span className="fp_selected_radio"></span>
                                </label>
                            })}
                        </div>
                        {/* <div className="fpLoadMore">
                            <a className="fp-btn">Load More</a>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="fp-btn" onClick={() => uploadUrl()}>Apply</button>
                </div>
            </div>
        )
    }

    const displayName = (name) => {
        if (name === "Sales Alert") {
            return "Sales"
        } else if (name === "Leads Alert") {
            return "Leads"
        } else if (name === "Visitor Alert") {
            return "Visitor"
        }
    }

    return <>
        <div className={shownPopup ? `fp-delete-modal` : `fp-delete-modal display_model`} id="uploadImage">
            <div className="modal-dialog modal-two">
                <div className="fp_modal_content">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => closePopup()} ></button>
                        </div>

                        <div className="modal-body fp_model_user">


                            <Tabs defaultActiveKey="tab1" activeKey={activeTab} onSelect={handleTabSelect} className="tab-menu">

                                <Tab eventKey="tab1" title={
                                    <div className="fp_front_icons">
                                        <svg width="30px" height="30px" version="1.1" x="0" y="0" viewBox="0 0 48 48"><g><path d="M16.82 18.74a3.71 3.71 0 1 0-3.7-3.74 3.73 3.73 0 0 0 3.7 3.74zm0-5.42a1.71 1.71 0 1 1-1.7 1.7 1.71 1.71 0 0 1 1.7-1.7zM33.8 29.42A1 1 0 0 0 33 29a1 1 0 0 0-.73.32L29 32.53A1 1 0 0 0 29 34a1 1 0 0 0 1.42 0L32 32.42V36a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-3.55l1.49 1.49a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.42z" opacity="1" /><path d="M27.33 27.33A8 8 0 0 0 25.07 32H10.61A1.63 1.63 0 0 1 9 30.37v-.24l6.35-6.28a2.74 2.74 0 0 1 3.59-.2l1.61 1.58.12.1a1.91 1.91 0 0 0 2.23 0l5.19-5.1A4.86 4.86 0 0 1 33 19a4.79 4.79 0 0 1 1.48.8l.54.53V21a1 1 0 1 0 2 0v-9.39A3.62 3.62 0 0 0 33.37 8H10.61A3.63 3.63 0 0 0 7 11.61v18.76A3.63 3.63 0 0 0 10.61 34h14.46a8 8 0 1 0 2.26-6.66zM10.61 10h22.76A1.62 1.62 0 0 1 35 11.61v6.18a6.24 6.24 0 0 0-1.41-.66 6.85 6.85 0 0 0-6.9 1.63l-4.93 4.85-.47-.46-.74-.72a4.77 4.77 0 0 0-6.62 0L9 27.32V11.61A1.62 1.62 0 0 1 10.61 10zm26.62 27.23a6 6 0 1 1 0-8.49 6 6 0 0 1 0 8.49z" opacity="1" /></g></svg>
                                        <span>Upload Image</span>
                                    </div>
                                }
                                >
                                    <div className="tab-pane fade show active">
                                        <div className="fp_modal_tab_content">
                                            <div className="fp-upload-image mb-40">
                                                <div className="fp_uploadImage">
                                                    <div className="fp_upload cl_uploadnot">
                                                        <input type="file" className="fp_file_input" id="" name="wl_logo" onChange={(e) => (handleUpload(e))} />
                                                        <div className="fp_uploadIcon">
                                                            <img id="wl_logo" src="https://img.flexifunnels.com/flexiproof-assets/upload_logo.svg" alt="Upload Logo" />
                                                        </div>
                                                        <div className="fp_uploadDetail">
                                                            <h6 className="">Upload Image</h6>
                                                            <span className="fpRcndedSize">Recommend image size 80X80px</span>
                                                            <p className="">drag & drop your image here <br />OR
                                                                <span>browse</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fp_uploadedImg_main">                                        
                                                <div className="fpUpldedHead">
                                                    <svg width="26px" height="26px" version="1.1" x="0" y="0" viewBox="0 0 48 48"><g><path d="M16.82 18.74a3.71 3.71 0 1 0-3.7-3.74 3.73 3.73 0 0 0 3.7 3.74zm0-5.42a1.71 1.71 0 1 1-1.7 1.7 1.71 1.71 0 0 1 1.7-1.7zM33.8 29.42A1 1 0 0 0 33 29a1 1 0 0 0-.73.32L29 32.53A1 1 0 0 0 29 34a1 1 0 0 0 1.42 0L32 32.42V36a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-3.55l1.49 1.49a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.42z" opacity="1" /><path d="M27.33 27.33A8 8 0 0 0 25.07 32H10.61A1.63 1.63 0 0 1 9 30.37v-.24l6.35-6.28a2.74 2.74 0 0 1 3.59-.2l1.61 1.58.12.1a1.91 1.91 0 0 0 2.23 0l5.19-5.1A4.86 4.86 0 0 1 33 19a4.79 4.79 0 0 1 1.48.8l.54.53V21a1 1 0 1 0 2 0v-9.39A3.62 3.62 0 0 0 33.37 8H10.61A3.63 3.63 0 0 0 7 11.61v18.76A3.63 3.63 0 0 0 10.61 34h14.46a8 8 0 1 0 2.26-6.66zM10.61 10h22.76A1.62 1.62 0 0 1 35 11.61v6.18a6.24 6.24 0 0 0-1.41-.66 6.85 6.85 0 0 0-6.9 1.63l-4.93 4.85-.47-.46-.74-.72a4.77 4.77 0 0 0-6.62 0L9 27.32V11.61A1.62 1.62 0 0 1 10.61 10zm26.62 27.23a6 6 0 1 1 0-8.49 6 6 0 0 1 0 8.49z" opacity="1" /></g></svg>
                                                    <span>Uploaded Images</span>
                                                </div> 
                                                <div className="tab-pane">
                                                    <div className="fp_modal_tab_content">
                                                        <div className="fpImgLibrary">
                                                            <div className="fp_layoutScreen fp_chooseImage_layout">
                                                                {images?.map((libraryImage, index) => {
                                                                    return <label key={`libraryImage_${index}`} className="layout_label" onClick={() => { setSelectedUrl(libraryImage?.img_path) }}>
                                                                        <img src={libraryImage.img_path} alt="" />
                                                                        <input type="radio" className="select_box_radio selectedLibImg" name="choose_image_box" />
                                                                        <span className="fp_selected_radio"></span>
                                                                    </label>
                                                                })}
                                                            </div>


                                                        </div>
                                                        {pagination && pagination?.currentPage < pagination?.totalPage &&
                                                            <div className="fpLoadMore">
                                                                <a className="fp-btn" onClick={() => { handleLoadMore(pagination.currentPage + 1) }}> Load More</a>
                                                            </div>
                                                        }
                                                        {
                                                            imageLoading &&  <Loader />
                                                        }
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="fp-btn" onClick={() => uploadUrl()}>Apply</button>
                                                    </div>
                                                </div>                                      
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="tab2" title={
                                    <div className="fp_front_icons">
                                        <svg width="22px" height="30px" version="1.1" x="0" y="0" viewBox="0 0 100 100"><g><path d="M93.75 34.375A9.375 9.375 0 0 0 84.375 25H29.688a7.814 7.814 0 0 0-7.813 7.813v48.064a9.375 9.375 0 0 0 9.375 9.375h53.125a9.375 9.375 0 0 0 9.375-9.375V34.375zm-6.25 0v46.502a3.125 3.125 0 0 1-3.125 3.125H31.25a3.125 3.125 0 0 1-3.125-3.125V32.813a1.562 1.562 0 0 1 1.563-1.563h54.687a3.125 3.125 0 0 1 3.125 3.125z" opacity="1" /><path d="M85.655 24.23v-5.455A9.375 9.375 0 0 0 76.28 9.4H15.647a9.375 9.375 0 0 0-9.375 9.375v53.1a9.375 9.375 0 0 0 9.375 9.375h6.228a3.126 3.126 0 0 0 0-6.25h-6.228a3.125 3.125 0 0 1-3.125-3.125v-53.1a3.125 3.125 0 0 1 3.125-3.125H76.28a3.125 3.125 0 0 1 3.125 3.125v5.455a3.126 3.126 0 0 0 6.25 0z" opacity="1" /><path d="M28.743 83.432 42.26 59.323a3.127 3.127 0 0 1 5.452 0l9.647 17.205a3.126 3.126 0 0 0 4.254 1.198 3.126 3.126 0 0 0 1.197-4.254l-9.647-17.206a9.374 9.374 0 0 0-16.354 0L23.291 80.375a3.126 3.126 0 0 0 5.452 3.057z" opacity="1" /><path d="m58.04 69.749 9.834-6.869a3.126 3.126 0 0 1 4.515 1.033l12.129 21.633a3.128 3.128 0 0 0 4.255 1.198 3.126 3.126 0 0 0 1.197-4.254L77.841 60.857a9.377 9.377 0 0 0-13.546-3.101l-9.835 6.87c-1.413.987-1.76 2.937-.772 4.351s2.938 1.76 4.352.772zM76.407 34.537c-3.946 0-7.202 3.259-7.202 7.336s3.256 7.337 7.202 7.337c3.947 0 7.202-3.26 7.202-7.337s-3.255-7.336-7.202-7.336zm0 6.25c.555 0 .952.513.952 1.086s-.397 1.087-.952 1.087c-.554 0-.952-.514-.952-1.087s.398-1.086.952-1.086z" opacity="1" /></g></svg>
                                        <span>{displayName(type)} Image library</span>
                                    </div>
                                }>
                                    {type === "Sales Alert" && showSalesImage()}
                                    {type === "Leads Alert" && showLeadsImage()}
                                    {type === "Visitor Alert" && showVisitorImage()}

                                </Tab>

                              { type !== "Visitor Alert" &&  <Tab eventKey="tab3" title={
                                    <div className="fp_front_icons">
                                        <svg width="22px" height="30px" version="1.1" x="0" y="0" viewBox="0 0 24 24"><g><path d="M12 22.75C6.072 22.75 1.25 17.928 1.25 12S6.072 1.25 12 1.25 22.75 6.072 22.75 12 17.928 22.75 12 22.75zm0-20c-5.101 0-9.25 4.149-9.25 9.25s4.149 9.25 9.25 9.25 9.25-4.149 9.25-9.25S17.101 2.75 12 2.75zM10.25 10c0-1.014-.525-1.75-1.25-1.75S7.75 8.986 7.75 10s.525 1.75 1.25 1.75 1.25-.736 1.25-1.75zm6.347 5.454a.75.75 0 1 0-1.194-.908A4.25 4.25 0 0 1 12 16.25a4.269 4.269 0 0 1-3.391-1.701.75.75 0 1 0-1.199.902A5.775 5.775 0 0 0 12 17.75c1.812 0 3.487-.837 4.597-2.296zm-.349-4.389a.75.75 0 0 0 .465-.953c-.25-.728-.938-1.217-1.711-1.217s-1.459.489-1.711 1.216a.75.75 0 0 0 1.418.49.313.313 0 0 1 .586-.001.748.748 0 0 0 .953.465z" opacity="1" /></g></svg>
                                        <span>{displayName(type)} Animated Images</span>
                                    </div>
                                }>

                                    {type === "Sales Alert" && showSalesEmoji()}
                                    {type === "Leads Alert" && showLeadsEmoji()}

                                </Tab>}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}