import React from 'react';
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import App from './App';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Index() {
    return (
        <>
         <App />
            <ToastContainer
                position="top-right"
                autoClose={2000}
                theme="dark"
                closeOnClick
            />
        </>
       
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);
