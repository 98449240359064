import React, { useState } from 'react';
import './styles/global.css';
import ApiRouting from './AppRouting';
import { Providers } from './redux/provider';


function App(props) {
    return (
        <div className="App">
            <Providers>
                <ApiRouting />
            </Providers>
        </div>
    );
}

export default App;