import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import CampaignService from '../service/CampaignService';
import dayjs from "dayjs";
import Loader from './common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from './common/Pagination';
import CampaingsRecords from './CampaingsRecords';
import commonSlice, { removeState } from '../redux/slices/commonSlice';
import PageService from '../service/PageService';
import { toast } from "react-toastify";

function Campaings() {
    const [refresh, setRefresh] = useState(false)
    const [currentopen, setCurrentopen] = useState(0)
    const [loading, setLoading] = useState(false)
    const [campaigns, setCampaigns] = useState([])
    const [campaigns1, setCampaigns1] = useState([])
    const [searchFilter, setSearchFilter] = useState()
    const [selectedSortBy, setSelectedSortBy] = useState("descending")
    const [sortBy, setSortBy] = useState({
        isAscending: true,
        isDescending: false,
    })
    const [paging, setPaging] = useState({
        noOfPages: 0,
        page: 1
    });
    const [campaignCount, setCampaignCount] = useState(0)

    const userDetails = useSelector((data) => data.common)
    let navigate = useNavigate ();
    const dispatch = useDispatch();

    useEffect(() => {
        if (refresh) {
            window.location.reload();
        }
    }, [refresh])

    useEffect(() => {
        if (userDetails?.token) {
            getCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: paging.page })
        }
    }, [userDetails?.token, selectedSortBy])

    useEffect(() => {
        if (campaigns1.length == 0 || !userDetails?.user.id) return
        campaigns1.map((campaign, index) => {
            getAlertSettings({ user_id: userDetails?.user.id, token: userDetails?.token, prof_campaign_id: campaign.prof_campaign_id, index: index })
            getPageSettings({ user_id: userDetails?.user.id, token: userDetails?.token, prof_campaign_id: campaign.prof_campaign_id, index: index })
        })
        // setLoading(false)
    }, [campaigns1])

    const getAlertSettings = (formData) => {
        let index = formData.index 
        delete formData.index
        PageService.getAlertSettings(formData).then(resp => {
            if (resp.data.success) {
                resp.data.campaign_alert_settings.map(setting => {
                       setting.alert_customization = JSON.parse(setting.alert_customization)
                })
                campaigns1[index]["alertSettings"] = resp.data.campaign_alert_settings
                setCampaigns([...campaigns1])
            }
        }).catch(error => {
        });
    }

    const getPageSettings = (formData) => {
        let index = formData.index 
        delete formData.index
        PageService.getPageSettings(formData).then(resp => {
            if(resp.data.status){
                 resp.data.campaign_page_settings.map(setting => {
                    setting.page_customization = JSON.parse(setting.page_customization)
                 })
                 campaigns1[index]["pageSettings"] = resp.data.campaign_page_settings
            }
        }).catch(error => {
            
        });
    }

    const getCampaigns = (formData) => {
        setLoading(true)
        CampaignService.getCampaigns(formData).then(resp => {
            if (resp.data.success) {
                if (typeof resp?.data?.total_campaign === 'number' && !isNaN(resp?.data?.total_campaign)) {
                    let pageCount = Math.ceil(resp?.data?.total_campaign / 10);
                    setPaging({ ...paging, page:formData.page, noOfPages: resp.data.total_pages });
                }
                // setCampaigns(resp?.data?.campaign_list)
                setCampaigns1([...resp?.data?.campaign_list])
                setCampaignCount(resp?.data?.total_campaign)
                setLoading(false)
            }
        }).catch(error => {
            
            setLoading(false)
        });
    }

    const getSearchCampaigns = (formData) => {
        setLoading(true)
        CampaignService.getSearchCampaigns(formData).then(resp => {
            if (resp.data.success) {
                if (typeof resp?.data?.total_campaign === 'number' && !isNaN(resp?.data?.total_campaign)) {
                    let pageCount = Math.ceil(resp?.data?.total_campaign / 10);
                  
                    setPaging({ ...paging, page:formData.page, noOfPages: resp.data.total_pages });
                }
                setCampaigns1([...resp?.data?.campaign_list])
                setCampaignCount(resp?.data?.total_campaign)
                // setCampaigns(resp?.data?.campaign_list)
                setLoading(false)
            }
        }).catch(error => {
            if(error.response.status == 401){
                dispatch(removeState())
                navigate("/")
            } else if(error.code === "ERR_NETWORK"){
                toast.error(error.code)
                dispatch(removeState())
            navigate("/")
            } else {
                toast.error(error.code)
                dispatch(removeState())
            navigate("/")
            }
        });
    }

    const handleChange = (event) => {
        setSearchFilter(event.target.value);
    };

    const handleClear = () => {
        setSearchFilter();
        getCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: paging.page })
    };


    const sortedDate = (data) => {
        let sortData
        if (data === "descending") {
            sortData = campaigns.sort((a, b) => {
                const nameA = a.created_at
                const nameB = b.created_at
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
        } else {
            sortData = campaigns.sort((a, b) => {
                const nameA = a.created_at
                const nameB = b.created_at
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }
                return 0;
            });

        }
        setCampaigns([...sortData])
        return sortData
    }

    const sortedData = (data) => {
        let sortData
        if (selectedSortBy === "descending") {
            sortData = data.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
        } else {
            sortData = data.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }
                return 0;
            });

        }
        return sortData
    }

    const handleSorting = (value) => {
        sortedDate(value)
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            getSearchCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: paging.page, search_name: searchFilter, })
        }
    };

    const handleSearch = () => {
        if (!searchFilter) return
        getSearchCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: paging.page, search_name: searchFilter, })
    }

    const handleOnPageChange = (page) => {
        // console.log("page",page)
        searchFilter ? getSearchCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: page, search_name: searchFilter, }) : getCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: page })
        setPaging({ ...paging, page: page })
    }

    const updateCampaignStatus = (formData, index) => {
        CampaignService.updateCampaignStatus(formData).then((resp) => {
            if(resp.data.success){
                // console.log("status", resp.data.message)
                toast.success(`Campaign Status ${formData.status ?  "Enabled" : "Disabled"}`)
                campaigns1[index].status = formData.status
                setCampaigns1([...campaigns1])
            } else {
                toast.error(resp.data.message)
            }
    }).catch(error => {
        if(error.response.status == 401){
            dispatch(removeState())
            navigate("/")
        } else if(error.code === "ERR_NETWORK"){
            toast.error(error.code)
        } else {
            toast.error(error.code)
        }
    });
    }

    return (
        <>
            {loading && <Loader />}
            <div className="fp-main-content">
                <div className="fp-page-container">

                    <div className="fp-campaigns-wrapper">
                        <div className="fp-campaigns-head">
                            <div className="fp-title">
                                <span>
                                    <svg width="23px" height="23px" version="1.1" x="0" y="0" viewBox="0 0 512 512"><g><path fillRule="evenodd" d="m202.184 388.111 48.586 48.586c5.842 5.842 5.842 15.371 0 21.213l-20.045 20.045c-5.842 5.842-15.371 5.842-21.213 0l-59.988-59.988 46.141-26.64a531.777 531.777 0 0 1 6.519-3.216zm291.529-184.107c6.293 2.046 9.736 8.807 7.69 15.1-2.047 6.293-8.807 9.736-15.1 7.69l-43.637-14.178c-6.293-2.046-9.736-8.807-7.69-15.1s8.807-9.736 15.1-7.689zm-49.419-64.188c-5.74 3.314-13.079 1.347-16.392-4.393-3.314-5.739-1.347-13.079 4.392-16.392l39.415-22.757c5.74-3.314 13.079-1.347 16.392 4.392 3.314 5.74 1.347 13.079-4.392 16.393zM377.973 39.16c1.374-6.473 7.736-10.606 14.21-9.232 6.473 1.374 10.606 7.736 9.232 14.21l-9.541 44.88c-1.374 6.473-7.736 10.606-14.209 9.232s-10.606-7.736-9.232-14.209zM252.542 52.921c-11.463 6.618-15.428 21.414-8.809 32.877l131.543 227.84c6.619 11.464 21.414 15.427 32.878 8.809 11.463-6.618 15.427-21.414 8.809-32.877l-44.751-77.51 5.849-3.377c20.387-11.771 27.437-38.081 15.667-58.469-11.771-20.387-38.082-27.437-58.469-15.667l-5.849 3.377-43.991-76.195c-6.618-11.461-21.413-15.426-32.877-8.808zM29.124 360.719l10.483 18.158c17.823 30.87 57.662 41.545 88.533 23.722l45.78-26.431-85.777-148.57-45.78 26.431c-30.87 17.823-41.545 57.662-23.722 88.532zm79.308-145.772c49.664-34.11 87.897-72.052 117.556-111.883l124.703 215.993c-49.324 5.769-101.3 19.91-155.672 45.865z" clipRule="evenodd" opacity="1"/></g></svg>        
                                </span>
                                <h3>                                    
                                    My Campaigns <span>{`[${campaignCount || 0}]`}</span>
                                </h3>
                            </div>
                            <div className="fp-campaigns-filter">
                                <div className="fp-search-box">
                                    <img src="https://img.flexifunnels.com/flexiproof-assets/search-icon.svg" alt="" onClick={() => handleSearch()} />
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchFilter || ""}
                                        onChange={handleChange}
                                        onKeyDown={handleEnterKey}
                                    />
                                    {searchFilter && <button type="button" className='clr-btn' onClick={handleClear}>
                                        <img src="https://img.flexifunnels.com/flexiproof-assets/cancel.svg" alt="" />
                                    </button>}
                                </div>
                                {/* <div className="fp-camp-sorting">
                                    <label>Sort By Date:</label>
                                    <select name="" id="" onChange={(e) => sortedDate(e.target.value)}>
                                        <option value="descending">Desc. Order</option>
                                        <option value="ascending">Assen Order</option>
                                    </select>
                                </div> */}
                                <div className="fp-head-btn">
                                    <Link to="/createcampaign" className="fp-btn"> <img src="https://img.flexifunnels.com/flexiproof-assets/plus.png" alt=""/> Create New Campaign</Link>
                                </div>

                            </div>
                        </div>
                        <div className="fp-campaigns-body">
                            <div className="fp-camp-tabular-wrapper">
                                <div className="fp-table-responsive">
                                    <table className="fp-custom-table fp-table-head">
                                        <thead>
                                            <tr>
                                                <th>S.N</th>
                                                <th>Name</th>
                                                <th>Alert(s)</th>
                                                <th>Created On</th>
                                                <th>Updated On</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table className="fp-custom-table">
                                        <tbody>
                                            {/* Row  */}
                                            {campaigns1?.map((campaign, index) => {
                                                return <tr key={index}>
                                                    <td colSpan="8" className="fp-table-row-wrapper">
                                                        <CampaingsRecords 
                                                        currentopen={currentopen} 
                                                        setcurrentopen={(value) => setCurrentopen(value)} 
                                                        campaign={campaign} 
                                                        index={index} 
                                                        isUpdateStatus = {(index) =>{
                                                            
                                                            let obj = {
                                                                id: campaign.prof_campaign_id,
                                                                type: "campaign",
                                                                status: campaign.status == 1 ? 0 : 1,
                                                                user_id: userDetails.user.id,
                                                                token: userDetails.token

                                                            }
                                                            updateCampaignStatus(obj,index)
                                                        }}
                                                        isDeleted={(value) => {
                                                            // setRefresh(true)
                                                            searchFilter ? getSearchCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: 1, search_name: searchFilter, }) : getCampaigns({ user_id: userDetails?.user.id, token: userDetails?.token, page: 1 })
                                                        }} 
                                                            />
                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        {campaigns1.length > 0 && <Pagination paging={paging} onClick={(page) => handleOnPageChange(page)} />}
                    </div>

                </div>
            </div>

            {/* Record Data Not Found */}

            {!loading && campaigns1.length == 0 && <div className='fp-no-records'>
                <img src="https://img.flexifunnels.com/flexiproof-assets/not-found.svg" alt="" />
                <p>Oops! No record found.. </p>
            </div>}



        </>
    )
}

export default Campaings;