import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
import svg from "../components/svg";
import { useDispatch, useSelector } from 'react-redux';
import { removeState } from '../redux/slices/commonSlice';

function Header() {

    const [toggleViewMode, setToggleViewMode] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const componentRef = useRef(null);

    const dispatch = useDispatch();

    let navigate = useNavigate();

    const userDetails = useSelector((data) => data.common)

    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const handleLogout = () => {
        dispatch(removeState())
        navigate("/")
    }

    useEffect(() => {
        const handleOutSideClick = (event) => {
            const isClickedInsideUserBox = event.target.closest(".user-info-wrapper");
            if (!isClickedInsideUserBox) {
                setIsActive(false);
            }
        };

        window.addEventListener("mousedown", handleOutSideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [componentRef]);

    return (
        <>
            <header className={`fp-header-wrapper ${toggleViewMode ? 'fp-nav-open' : ''}`}>
                <div className="fp-container">
                    <div className="header-row">
                        <div className="logo-wrap">
                            <div className="nav-toggle" onClick={() => setToggleViewMode(!toggleViewMode)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <Link to="/campaigns">
                                <img src="https://img.flexifunnels.com/flexiproof-assets/logo.svg" alt="logo" />
                            </Link>
                        </div>
                        <div className="navigation-wrapper">
                            <ul>
                                
                                <li>
                                    <Link to="/campaigns">
                                        <svg width="23px" height="23px" version="1.1" x="0" y="0" viewBox="0 0 512 512"><g><path fillRule="evenodd" d="m202.184 388.111 48.586 48.586c5.842 5.842 5.842 15.371 0 21.213l-20.045 20.045c-5.842 5.842-15.371 5.842-21.213 0l-59.988-59.988 46.141-26.64a531.777 531.777 0 0 1 6.519-3.216zm291.529-184.107c6.293 2.046 9.736 8.807 7.69 15.1-2.047 6.293-8.807 9.736-15.1 7.69l-43.637-14.178c-6.293-2.046-9.736-8.807-7.69-15.1s8.807-9.736 15.1-7.689zm-49.419-64.188c-5.74 3.314-13.079 1.347-16.392-4.393-3.314-5.739-1.347-13.079 4.392-16.392l39.415-22.757c5.74-3.314 13.079-1.347 16.392 4.392 3.314 5.74 1.347 13.079-4.392 16.393zM377.973 39.16c1.374-6.473 7.736-10.606 14.21-9.232 6.473 1.374 10.606 7.736 9.232 14.21l-9.541 44.88c-1.374 6.473-7.736 10.606-14.209 9.232s-10.606-7.736-9.232-14.209zM252.542 52.921c-11.463 6.618-15.428 21.414-8.809 32.877l131.543 227.84c6.619 11.464 21.414 15.427 32.878 8.809 11.463-6.618 15.427-21.414 8.809-32.877l-44.751-77.51 5.849-3.377c20.387-11.771 27.437-38.081 15.667-58.469-11.771-20.387-38.082-27.437-58.469-15.667l-5.849 3.377-43.991-76.195c-6.618-11.461-21.413-15.426-32.877-8.808zM29.124 360.719l10.483 18.158c17.823 30.87 57.662 41.545 88.533 23.722l45.78-26.431-85.777-148.57-45.78 26.431c-30.87 17.823-41.545 57.662-23.722 88.532zm79.308-145.772c49.664-34.11 87.897-72.052 117.556-111.883l124.703 215.993c-49.324 5.769-101.3 19.91-155.672 45.865z" clipRule="evenodd" opacity="1"/></g></svg>        
                                        <span className="nav-title">My Campaigns</span>
                                    </Link>
                                </li>
                               
                            </ul>
                        </div>
                        <div className="fpHeaderBtn">
                            <a href="https://app.flexifunnels.com/" className="fp-btn" target='_blank'>
                                <span className="fp-svg-icons">
                                    {svg.app.switchAccountIcon}
                                </span>
                                Switch to FlexiFunnels
                            </a>
                        </div>
                        <div className={`user-info-wrapper`} >
                            <div className="user-info" onClick={toggleClass}>
                                <div className="user-img">                                    
                                    <span>{userDetails?.user?.name?.charAt(0)||""}</span>
                                </div>
                                <div className="drop-down-header">
                                    <h4>{userDetails?.user?.name}</h4>
                                </div>
                            </div>
                            <div className={`user-info-box ${isActive ? 'show' : ''}`}>
                                <ul>
                                    {/* <li>
                                        <Link href="/profile">
                                            <span className="fp-svg-icons">
                                                {svg.app.userIcon}
                                            </span>
                                            Profile
                                        </Link>
                                    </li> */}
                                    <li>
                                        <a href="https://app.flexifunnels.com/" target='_blank'>
                                            <span className="fp-svg-icons">
                                                {svg.app.switchAccountIcon}
                                            </span>
                                            Switch to FlexiFunnels
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => handleLogout()}>
                                            <span className="fp-svg-icons">
                                                {svg.app.logoutIcon}
                                            </span>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;