// import axios from "axios";
import ApiService from "./ApiService";
import axios from '../utility/interceptor';

class ProjectService {
    getProjects(formData) {
        return axios.post(`${ApiService.Project}/project-list`, formData)
    }
}

export default new ProjectService();