// import axios from "axios";
import ApiService from "./ApiService";
import axios from '../utility/interceptor';

class CampaignService {

    createCampaign(formData) {
        return axios.post(`${ApiService.Campaign}/save-campaign-name`, formData)
    }

    getCampaign(formData) {
        return axios.post(`${ApiService.Campaign}/campaign-by-id`, formData)
    }

    updateCampaignStatus(formData) {
        return axios.post(`${ApiService.Campaign}/update-status-campaign`, formData)
    }

    getCampaigns(formData) {
        return axios.post(`${ApiService.Campaign}/campaign-list`, formData)
    }

    getSearchCampaigns(formData) {
        return axios.post(`${ApiService.Campaign}/search-campaign-list`, formData)
    }

    campaignSetting(formData) {
        return axios.post(`${ApiService.Campaign}/save-campaign-setting`, formData)
    }

    getCampaignSettings(formData) {
        return axios.post(`${ApiService.Campaign}/campaign-settings-list`, formData)
    }

    deleteCampaign(formData) {
        return axios.post(`${ApiService.Campaign}/delete-campaign`, formData)
    }

    uploadImage(formData) {
        return axios.post(`${"https://img.myflxfnls.com/upload-img"}`, formData)
    }

    getUserLocation(formData) {
        return axios.get(`https://geo.flexifunnels.com/ipinfo/`, formData)
    }

    saveEmbedCode(formData){
        return axios.post(`${ApiService.Campaign}/save-embed-code`, formData)
    }

    getPublishUrl(formData) {
        return axios.post(`${ApiService.Campaign}/published-url-list`, formData)
    }

    saveImagelibrary(formData) {
        return axios.post(`${ApiService.Campaign}/save-img-library`, formData)
    }

    getImagelibrary(formData) {
        return axios.post(`${ApiService.Campaign}/library-img-list`, formData)
    }

}

export default new CampaignService();