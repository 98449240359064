export default function Loader(props) {

    return (
        <div className="fp-preloader-wrap">
            <div className="fp-preloader">
                <img src="https://img.flexifunnels.com/flexiproof-assets/preloader.svg" alt=""/>
               {props?.isPublished && <p>Your page is being published. <br/> Please check the URL after 5 minutes.</p>}
            </div>
        </div>
    )
}