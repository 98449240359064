import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import commonSlice from './slices/commonSlice';
import { persistStore,
   persistReducer,
   FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER, } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  version: 1
};

const persistedReducer = persistReducer(persistConfig, commonSlice);

export const store = configureStore({
  reducer: {
    common: persistedReducer,
    // Add other reducers here if needed
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export default store;
