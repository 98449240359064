import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Campaigns from "./pages/Campaigns";
import Login from "./pages/Login";
import Header from "./components/Header";
import CreateCamapign from "./pages/CreateCampaign";
import ProtectedRoute from "./components/ProtectedRoute";
import YourReduxComponent from "./utility/HandleToken";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { removeState, setUserDetails } from "./redux/slices/commonSlice";
import { toast } from "react-toastify";
import AuthService from "./service/AuthService";
import { jwtDecode } from "jwt-decode";
import Loader from "./components/common/Loader";

export default function ApiRouting(location) {

    const [loading, setLoading] = useState(false)
    const [isLogin, setislogin] = useState(false)

    const userDetails = useSelector((data) => data.common)
    const token = useSelector((data) => data.common.token)
    const dispatch = useDispatch();

    let path = window.location.pathname

    const autologin = (outputString) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            AuthService.login(outputString)
                .then((res) => {
                    if (res.status === 200) {
                        // dispatch(setUserDetails({ user: res.data?.User, token: access_token }));
                        resolve(res.data);
                    } else {
                        toast.error("Error");
                        reject("Login failed");
                    }
                })
                .catch((error) => {
                    toast.error("Error");
                    reject(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    useEffect(() => {
        if (path === "/login") {

            const queryString = window.location.search.substring(1);
            if (queryString) {
                const outputString = queryString.substring(5);
                autologin(outputString)
                    .then((resp) => {
                        setislogin(true)
                        dispatch(setUserDetails({ user: resp.User, token: resp.access_token }));
                        setLoading(false)
                    })
                    .catch((error) => {
                        toast.error(error.message);
                        console.error("Login failed:", error);
                    });
            }
        }
    }, [path]);

    const checktokenExipiry = () => {
        const queryString = window.location.search.substring(1);
        if (!isLogin && path === "/login" && queryString) {
            return false
        } else {
            if (token) {
                const currentTime = Date.now() / 1000;
                let decodeToken = jwtDecode(token)
                if (decodeToken.exp < currentTime) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    }


    return (
        <>
            {loading && <Loader />}
            {checktokenExipiry()  ? <Router>
                {userDetails?.token?.length > 1 && <Header />}
                {userDetails?.token?.length > 1 && <YourReduxComponent />}
                <Routes>
                    <Route path="/login" element={userDetails?.token?.length > 1 ? <Navigate to="/campaigns" /> : <Login />} />
                    <Route path="/campaigns" element={<ProtectedRoute><Campaigns /></ProtectedRoute>} />
                    <Route path="/createcampaign" element={<ProtectedRoute><CreateCamapign /></ProtectedRoute>} />
                    <Route path="/createcampaign/:id" element={<ProtectedRoute><CreateCamapign /></ProtectedRoute>} />
                    <Route path="*" element={userDetails?.token?.length > 1 ? <Navigate to="/campaigns" /> : <Navigate to="/login" />} />
                </Routes>
            </Router> :
             <Router>
                <Routes>
                <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<Navigate to="/login" />} />
                </Routes>
                </Router>}

        </>
    )
}