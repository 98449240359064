import { createSlice, nanoid } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: 'userSlice',
  initialState: {
    user: {},
    token: "",
    campaign : {}
  },
  reducers: {
    setUserDetails: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
    },

    createCampaign: (state, action) => {
      state.campaign = action.payload;
    },

    updateCampaign: (state, action) => {
      state.campaign = action.payload;
    },

    removeState: (state, action) => {
      state.user = {};
      state.token = "";
    }

  },
});

export const { setUserDetails, removeState, updateCampaign, createCampaign} = commonSlice.actions;
export default commonSlice.reducer;


