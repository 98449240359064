const API_HOST = "https://bridge.flexifunnels.com/api"

class ApiService {
    static Login = `${API_HOST}/flexiproof-autologin`;
    static Campaign = `${API_HOST}`;
    static Project = `${API_HOST}`;
    static Product = `${API_HOST}`;
    static Page = `${API_HOST}`;
    static ImageUpload = ` https://img.myflxfnls.com/upload-img`
}

export default ApiService