import { useEffect } from "react"

export default function AlertModel(props) {

    useEffect(() => {
        if (props?.alert?.status == true) {
            setTimeout(() => {
                handleClose()
            }, 4000)
        }
    }, [props?.alert?.status == true])


    const handleClose = () => {
        props?.onClose(false)
    }

    return (
        <>
            {props?.alert?.status && props?.alert?.type === "success" && <div className="fp_success_msg">
                <div className="fp_close_icon">
                    <span onClick={() => handleClose()}>×</span>
                </div>
                <div className="fp_success_flex">
                    <div className="fp_yeah">
                        <h5>Great Success!</h5>
                        <p>{props?.alert?.message}</p>
                    </div>
                </div>
            </div> } 
            {props?.alert?.status && props?.alert?.type === "error" &&<div className="fp_error_msg">
                <div className="fp_close_icon">
                    <span onClick={ () => handleClose()}>×</span>
                </div>
                <div className="fp_success_flex">
                    <div className="fp_yeah">
                        <h5>Oh no! Error</h5>
                        <p>{props?.alert?.message}</p>
                    </div>
                </div>
            </div>}
        </>
    )
}