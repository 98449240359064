import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import tokenManager from './tokenManager'; // Import the token manager
import { useNavigate } from 'react-router-dom';
import { removeState } from '../redux/slices/commonSlice';

const YourReduxComponent = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate ();

  useEffect(() => {
    const handleTokenRemoved = () => {
        dispatch(removeState())
        navigate("/")
    };

    tokenManager.on('tokenRemoved', handleTokenRemoved);

    return () => {
      tokenManager.off('tokenRemoved', handleTokenRemoved);
    };
  }, [dispatch]);
};

export default YourReduxComponent;