import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageService from "../service/PageService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "./common/Loader";
import CampaignService from "../service/CampaignService";
import Dropdown from "./common/multidropdown";
// import GroupedSelect from "./common/groupedDropdown";
import { MobilePositions, displayCount } from "../utility/constant";
import AutoCompleteDropDown from "./common/autoCompleteDropdown";
import TestAutoCompleteDropDown from "./common/testAutoComplete";
import MultiSelectAutoComplete from "./common/multiSelectAutoComplete";


export default function PageSetting(props) {

    const [pageSetting, setPageSetting] = useState({
        position: {
            TopRight: false,
            TopLeft: false,
            BottomRight: false,
            BottomLeft: true,
        },
        isWidgetUrl: false,
        isMobileAlert: false,
        mobileViewPosition : "BottomLeft",
        url: "",
        customiseAlertFrequency: {
            pageLoadAlertDelay: 4,
            singleAlertDuration: 4,
            delayBetwwenTwoAlerts: 5,
            displayAlertCount: 10,
            showAlertsFrom: 365
        },
        funnel_id: "",
        funnel_page_id: "",
    })

    const [dropdownActive, setDropdownActive] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [projects, setProjects] = useState([]);
    const [pages, setPages] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [loading, setLoading] = useState(false);
    const [displayCounts, setDisplayCounts] = useState(displayCount);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [publishedPages, setPublishedPages] = useState([]);
    const [publishedProjects, setPublishedProjects] = useState([]);


    const componentRef = useRef(null);
    const userDetails = useSelector((data) => data.common)
    let navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props?.pageSettings[0]?.page_customization && !props?.projects && !userDetails) return
        setProjects(props?.projects)
       
        let selectedProject1 = getProjectAndPages(props?.pageSettings[0]?.page_customization, props?.projects)
        if (!!selectedProject1) {
            getPages1({ user_id: userDetails.user.id, token: userDetails.token, funnel_id: selectedProject1?.funnel_id, campaign_id: props?.camapaignDetails?.prof_campaign_id })
            setSelectedProject(selectedProject1)
            setPageSetting({ ...props?.pageSettings[0]?.page_customization })
        }
    }, [props, userDetails,props?.activeTabChange])

    useEffect(() => {
        if (!props?.projects) return
        setProjects(props?.projects)
    }, [props?.projects])

    useEffect(() => {
        if (!selectedProject) return
    }, [selectedProject])

    useEffect(() => {
        const handleOutSideClick = (event) => {
            const isClickedInsideUserBox = event.target.closest(".selected-option");
            const isCheckbox = event.target.closest(".special-service-drop-down");
            if (!isClickedInsideUserBox && !isCheckbox) {
                setDropdownActive(false);
            }
        };
        window.addEventListener("mousedown", handleOutSideClick);
        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [componentRef]);

    const getProjectAndPages = (alertSettingDetail, projectList) => {
        if (!alertSettingDetail) {
            return
        }

        let funnel_page_id_string = alertSettingDetail?.funnel_page_id;
        if (typeof funnel_page_id_string === 'string') {
            funnel_page_id_string = funnel_page_id_string.slice(1, -1);
            let funnel_page_id_array = funnel_page_id_string.split(',');
            let funnel_page_id = funnel_page_id_array.map(id => id.trim());
            alertSettingDetail.funnel_page_id = funnel_page_id;
        } else {
            // console.log('alertSettingDetail.funnel_page_id is not a string');
        }

        let funnel_id_string = alertSettingDetail?.funnel_id;
        if (typeof funnel_id_string === 'string') {
            funnel_id_string = funnel_id_string.slice(1, -1);
            let funnel_id_array = funnel_id_string.split(',');
            let funnel_id = funnel_id_array.map(id => id.trim());
            alertSettingDetail.funnel_id = funnel_id;
        } else {
            // console.log('alertSettingDetail.funnel_id is not a string');
        }

        setPublishedPages(alertSettingDetail?.funnel_page_id)
        setPublishedProjects( alertSettingDetail.funnel_id)

        const selectedProjectList = projectList?.filter(project =>
            alertSettingDetail?.funnel_id?.includes(project.funnel_id.toString())
        );

        if (selectedProjectList?.length > 0) {
            selectedProjectList?.map(project => {
                let projectsPages = getProjectPages({ user_id: userDetails.user.id, token: userDetails.token, funnel_id: project?.funnel_id, campaign_id: props?.camapaignDetails?.prof_campaign_id })
                let Selectedpages = []
                projectsPages?.then(pages => {
                    pages?.map(page => {
                        alertSettingDetail?.funnel_page_id?.map(id => {
                            if (page.funnel_page_id == id) {
                                page.checked = true
                                Selectedpages.push(page)
                                project["pages"] = Selectedpages
                            }
                        })
                    })
                })

            })
        }

        let updatedProjects = projectList.map(pr => {
            const selectedProject = selectedProjectList.find(slPR => slPR.funnel_id === pr.funnel_id);
            if (selectedProject) {
                pr["checked"] = true;
            } else {
                pr["checked"] = false;
            }
            return pr;
        });
        setProjects(updatedProjects)
        setSelectedProjects(selectedProjectList)
        return selectedProjectList[selectedProjectList.length - 1]
    }

    const getProjectPages = (formData) => {
        return new Promise((resolve, reject) => {
            PageService.getPages(formData)
                .then((resp) => {
                    if (resp.data.success) {
                        let updatedPages = structuredClone(resp?.data?.page_list)
                        resolve(updatedPages);
                    } else {
                        reject(new Error("Failed to fetch pages"));
                    }
                }).catch(error => {
                    // reject(error);
                });
        });
    }

    const getPages1 = (formData) => {
        PageService.getPages(formData).then(resp => {
            if (resp.data.success) {
                if (resp?.data?.page_list.length == 0) {
                    toast.error('Please select another project.');
                    setSelectedProject()
                    setPages([])
                    selectedOptions([])
                }
                resp?.data?.page_list?.map(page => {
                    page.checked = false
                })
                let selectedOption = []
                let updatedPages = structuredClone(resp?.data?.page_list)
                let selectedProject = selectedProjects.find(pr => pr.funnel_id == formData.funnel_id)
                let funnelPageIds = selectedProject?.pages.map(page => page.funnel_page_id);
                let idsArray = funnelPageIds;
                if (idsArray?.length > 0) {
                    updatedPages?.map(page => {
                        idsArray?.map(funnelPage => {
                            if (page?.funnel_page_id == funnelPage) {
                                page.checked = true
                                selectedOption.push(page.name)
                            }
                        })
                    })
                }
                setSelectedOptions(selectedOption);
                setPages([...updatedPages])
            }
        }).catch(error => {

        });
    }

    const handlePositionSelection = (type) => {
        Object.keys(pageSetting?.position)?.map(key => {
            if (key === type) {
                pageSetting.position[key] = true
            } else {
                pageSetting.position[key] = false
            }
        })
        setPageSetting({ ...pageSetting })
    }

    const handleOnChangeInput = (type, method, value) => {
        // value = parseInt(value)
        // let updatedValue
        // if (method === "plus") {
        //         updatedValue = value + 1
        // } else {
        //     let newVal = value
        //     if (newVal == 0 || newVal == 1) return
        //     updatedValue = value - 1
        // }


        value = parseInt(value)
        let updatedValue
        if (method === "plus") {
            if (value == 100) return
                updatedValue = value + 1
        } else {
            let newVal = value
            if (newVal == 0 || newVal == 1) return
            updatedValue = value - 1
        }











        setPageSetting({
            ...pageSetting,
            customiseAlertFrequency: {
                ...pageSetting.customiseAlertFrequency,
                [type]: updatedValue
            }
        })
    }

    const handleOnChangeAlertInput = (type, method, value) => {
        value = parseInt(value)
        let updatedValue
        if (method === "plus") {
            if (value == 100) return
                updatedValue = value + 1
        } else {
            let newVal = value
            if (newVal == 0 || newVal == 1) return
            updatedValue = value - 1
        }
        setPageSetting({
            ...pageSetting,
            customiseAlertFrequency: {
                ...pageSetting.customiseAlertFrequency,
                [type]: updatedValue
            }
        })
    }


    const checkValue = (val) => {
        if (val.length && val[0] == 0) {
            val = val.slice(1);
            return val
        } else {
            return val
        }
    }

    const handleInputTime = (type, value,e) => {
        
        if (e?.key === 'ArrowDown' && value <= 1) {
            e.preventDefault();
        }

        if (e?.key === 'ArrowUp' && value >= 100) {
            e.preventDefault();
        }

        if (value < 0 || !value) {
            value = 0
        }

        if (value >100) {
            value = 100
        }
        
        
        // if (e?.key === 'ArrowDown' && value <= 1) {
        //     e.preventDefault();
        // }

        // if (value < 0 || !value) {
        //     value = 0
        // }

        setPageSetting({
            ...pageSetting,
            customiseAlertFrequency: {
                ...pageSetting.customiseAlertFrequency,
                [type]: checkValue(value)
            }
        })
    }

    const handleInputAlertTime = (type, value,e) => {
        if (e?.key === 'ArrowDown' && value <= 1) {
            e.preventDefault();
        }

        if (e?.key === 'ArrowUp' && value >= 100) {
            e.preventDefault();
        }

        if (value < 0 || !value) {
            value = 0
        }

        if (value >100) {
            value = 100
        }

        setPageSetting({
            ...pageSetting,
            customiseAlertFrequency: {
                ...pageSetting.customiseAlertFrequency,
                [type]: checkValue(value)
            }
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown' && event.target.value <= 1) {
            event.preventDefault();
        }
    };

    const handleDisplayCount = (value) => {
        if(!value)return
        pageSetting.customiseAlertFrequency.showAlertsFrom = value
        setPageSetting({ ...pageSetting })
    }

    const saveEmbedCode = (formData) => {
        CampaignService.saveEmbedCode(formData).then((resp) => {
            if (resp.data.status) {
                // toast.success("Page Setting successfully update")
            }
        })
    }

    const getPageIds = (selectedPages) => {
        let seletedIds = [];
        selectedPages?.map((page) => {
            if (page.checked) {
                seletedIds.push(page.funnel_page_id);
            }
        });
        return JSON.stringify(seletedIds)
    }

    const getProjectIds = (selectedPr) => {
        let seletedIds = [];
        selectedPr?.map((page) => {
            if (page.checked) {
                seletedIds.push(page.funnel_id);
            }
        });
        return JSON.stringify(seletedIds)
    }

    const savePageStting = () => {

        let updatedProjects = selectedProjects?.filter(project => project?.pages?.length > 0)
        if (updatedProjects?.length == 0) {
            toast.error("Please select page")
            return
        }

        if(pageSetting.customiseAlertFrequency.singleAlertDuration == 0){
            toast.error("Duration to display single alert can not be 0 second")
            return
        }
        if(pageSetting.customiseAlertFrequency.delayBetwwenTwoAlerts  == 0){
            toast.error("Delay between two alerts must be greater than 0 second.")
            return
        }
       
        if(pageSetting.customiseAlertFrequency.displayAlertCount  == 0){
            toast.error("Display alert count can not be 0 second")
            return
        }

        if(pageSetting.customiseAlertFrequency.pageLoadAlertDelay  == 0){
            toast.error("Display page load alert must be greater than 0 second.")
            return
        }


        let selectedPages = []

        updatedProjects.map(pr => {
            pr.pages.map(page => {
                selectedPages.push(page)
            })
        })

        pageSetting.funnel_page_id = getPageIds(selectedPages)
        pageSetting.funnel_id = getProjectIds(updatedProjects)

        const selectedPagesInt = publishedPages.map(x => parseInt(x));
        const notInPages = selectedPagesInt.filter(x => !pageSetting.funnel_page_id.includes(x));

        let savedObj = {
            prof_campaign_id: props?.camapaignDetails?.prof_campaign_id,
            page_customization: JSON.stringify(pageSetting),
            token: userDetails.token
        }
        props.camapaignDetails.page_customization = pageSetting
        let createEmbededCode = `<div class="flexiproof" id=${props?.camapaignDetails?.prof_campaign_id}><script defer src="https://flexiproof.flexifunnels.com/widget.js"></script></div>`

        let funnel_page_idresult = "" + getPageIds(selectedPages)?.slice(1, -1) + "";
        let funnel_idresult = "" + getProjectIds(updatedProjects)?.slice(1, -1) + "";
        let funnel_page_id_delresult = notInPages.join(', ');

        let embededObj = {
            funnel_id: funnel_idresult,
            funnel_page_id: funnel_page_idresult,
            funnel_page_id_del:funnel_page_id_delresult,
            user_id: userDetails.user.id,
            flexiproof_embed: createEmbededCode,
            token: userDetails.token
        }

        saveEmbedCode(embededObj)
        PageService.savePageSetting(savedObj).then(resp => {
            if (resp.data.success) {
                toast.success("Page Settings Successfully Updated")
                props?.changeTab()
                // navigate('/campaigns')
            } else {
                toast.success(resp.data.message)
            }
        }).catch(error => {
            // toast.error("error")
        });
    }

   
    const handleOptions = (name, options) => {
        // console.log("options",options)
        let newList = [];
        if (name == "page") {
            options?.map(option => {
                let data = {
                    value: option?.funnel_page_id,
                    label: option?.name,
                    checked: option.checked,
                    path: option.path,
                    used_mode: option.used_mode,
                    campaign_name : option?.campaign_name
                }
                newList.push(data)
            })
            return newList
        }
    }

    const onChangeCallBack = (name, pagesIds) => {
        const updatedPages = pages?.map(page => ({
            ...page,
            checked: pagesIds.includes(page.funnel_page_id)
        }));

        const containsFunnelId = selectedProjects.some(project => project.funnel_id === selectedProject.funnel_id);

        if (!containsFunnelId) {
            let checkedPage = updatedPages.filter(page => page.checked)
            selectedProject["checked"] = true
            selectedProject["pages"] = checkedPage
            selectedProjects.push(selectedProject)

            // console.log("selectedProjects", selectedProjects)
            setSelectedProjects([...selectedProjects])
        } else {
            let selectedPages = updatedPages.filter(upPage => upPage.checked)
            selectedProjects.map(pr => {
                if (pr.funnel_id == selectedProject.funnel_id) {
                    pr.pages = selectedPages
                }
            })
            // console.log("updateselectedProjects", selectedProjects)
            setSelectedProjects([...selectedProjects])
        }
        setPages(updatedPages);
        const selectedNames = updatedPages?.filter(page => page.checked).map(page => page.name);
        setSelectedOptions(selectedNames);
    }

    const handleProjectChange = (value) => {
        if (!value) return
        let project = projects.find(project => project.funnel_id == value)
        setSelectedProject(project)
        setPages([])
        getPages1({ user_id: userDetails.user.id, token: userDetails.token, funnel_id: project?.funnel_id, campaign_id: props?.camapaignDetails?.prof_campaign_id })

    }

    const handleProjectOption = (options) => {
        let newList = [];
        options?.map(option => {
            let data = {
                value: option?.funnel_id,
                label: option?.name,
                checked: option.checked,
            }
            newList.push(data)
        })
        return newList

    }

    const showProjectName = () => {
        let names = selectedProjects?.map(item => item.name).join(', ');
           return "(" + names+")"
    }

    const handleWidgetPosition = (data) => {
        setPageSetting({...pageSetting, mobileViewPosition:data})
    }

    const handleProductChange = (selectedOption) => {
        console.log("selectedOption",selectedOption)
        let updatedPages = pages.map(page => {
            const selectedPages = selectedOption.find(option => option.value === page.funnel_page_id);
            if (selectedPages) {
                page.checked = true;
            } else {
                page.checked = false;
            }
            return page;
        });
        // setPages(updatedPages);

        // const updatedPages = pages?.map(page => ({
        //     ...page,
        //     checked: pagesIds.includes(page.funnel_page_id)
        // }));

        const containsFunnelId = selectedProjects.some(project => project.funnel_id === selectedProject.funnel_id);

        if (!containsFunnelId) {
            let checkedPage = updatedPages.filter(page => page.checked)
            selectedProject["checked"] = true
            selectedProject["pages"] = checkedPage
            selectedProjects.push(selectedProject)

            // console.log("selectedProjects", selectedProjects)
            setSelectedProjects([...selectedProjects])
        } else {
            let selectedPages = updatedPages.filter(upPage => upPage.checked)
            selectedProjects.map(pr => {
                if (pr.funnel_id == selectedProject.funnel_id) {
                    pr.pages = selectedPages
                }
            })
            // console.log("updateselectedProjects", selectedProjects)
            setSelectedProjects([...selectedProjects])
        }
        setPages(updatedPages);
        const selectedNames = updatedPages?.filter(page => page.checked).map(page => page.name);
        setSelectedOptions(selectedNames);


    }

      const showDefaultOption1 = (name, options) => {
        let newList = [];
        if (name == "pages") {
          let filterOption = options?.filter(option => option.checked)
          filterOption?.map(option => {
            if (option?.value == "all") {
              newList.push(option)
            } else {
              let data = {
                value: option?.funnel_page_id,
                label: option?.name,
              }
              newList.push(data)
            }
          })
          return newList
        }
      }

    return (
        <>
            {loading && <Loader />}
            <div className="fp-camp-step3">
                <div className="tab-main-box">
                    <div className="tab-create-camp-wrapper">
                        <div className="fp-create-camp-heading">
                            <span>
                                <svg width="19px" height="19px" version="1.1" x="0" y="0" viewBox="0 0 28 28"><g><g data-name="Layer 2"><path d="M22.6 1H5.4A4.4 4.4 0 0 0 1 5.4V7h26V5.4A4.4 4.4 0 0 0 22.6 1zM5 5a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm3 0a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM15.656 19.646a2.333 2.333 0 1 0-3.3.01 2.4 2.4 0 0 0 3.3-.01z" opacity="1" /><path d="M1 22.6A4.4 4.4 0 0 0 5.4 27h17.2a4.4 4.4 0 0 0 4.4-4.4V9H1zM9 17h.8a4.3 4.3 0 0 1 .524-1.266l-.56-.56a1 1 0 1 1 1.414-1.414l.561.561A4.294 4.294 0 0 1 13 13.8V13a1 1 0 0 1 2 0v.8a4.3 4.3 0 0 1 1.266.524l.56-.56a1 1 0 1 1 1.414 1.414l-.561.561A4.3 4.3 0 0 1 18.2 17h.8a1 1 0 0 1 0 2h-.8a4.3 4.3 0 0 1-.524 1.266l.56.56a1 1 0 1 1-1.414 1.414l-.561-.561A4.294 4.294 0 0 1 15 22.2v.8a1 1 0 0 1-2 0v-.8a4.3 4.3 0 0 1-1.266-.524l-.56.56a1 1 0 1 1-1.414-1.41l.561-.561A4.3 4.3 0 0 1 9.8 19H9a1 1 0 0 1 0-2z" opacity="1" /></g></g></svg>
                            </span>
                            <h4>Page Settings</h4>
                        </div>
                        <div className="fp-create-camp-body">
                            <div className="fp-frm-wrapper">
                                <div className="fp-input-wrap mb-30 fp_multi_select_option">
                                    <label>Choose Project <span><div className="info-tooltip">
                                        <img src="https://img.flexifunnels.com/flexiproof-assets/info.svg" alt="" />
                                        <span className="data">
                                        Select the projects and pages where you want to display the alerts. The widget alert will appear on the selected pages.
                                        </span>
                                    </div></span></label>
                                    <TestAutoCompleteDropDown
                                        options={projects?.length > 0 && handleProjectOption(projects) || []}
                                        defaultValue={projects?.length > 0 && selectedProject?.funnel_id && selectedProject?.funnel_id}
                                        onChangeCallBAck={handleProjectChange}
                                    />
                                    <div className="fsSlectProjct">
                                       <p>{selectedProjects.length>0 && showProjectName()}</p>
                                </div>
                                </div>

                                <div className={`fp-input-wrap mb-30 fp_multi_select_option ${selectedOptions?.length > 0  ? "" : "fpOnlyPgeSting"}`}>
                                    <label>Choose Pages</label>
                                    {/* <Dropdown
                                        disabled={selectedProject ? false : true}
                                        name="userIds"
                                        size="100%"
                                        placeHolder="Select Pages"
                                        value={onChangeCallBack}
                                        options={handleOptions("page", pages) || []}
                                        defaultOption={selectedOptions.length > 0 && selectedOptions}
                                    /> */}
                                    <div className="fp-input-wrap  fp_multi_select_option fp_page_setting_drop">
                                    <MultiSelectAutoComplete
                                        name="pageIds"
                                        options={pages?.length>0 && handleOptions("page", pages) || []}
                                        handleOnChange={handleProductChange}
                                        defaultOptions={pages?.length > 0 && showDefaultOption1("pages", pages) || []}
                                        placeholder="Select Pages"
                                    />
                                    </div>
                                </div>

                               
                                {/* Widget Position */}
                                <div className="fp-widget-position">
                                    <h4 className="fp-subtitle">Widget Position</h4>
                                    <div className="fp-widget-radio-btns">
                                        <ul>
                                            <li onClick={() => handlePositionSelection("BottomLeft")}>                                                
                                                <div className="fp-radio-btn">
                                                    <input type="radio" name="radio" checked={pageSetting?.position?.BottomLeft} readOnly />
                                                    <label>
                                                        <svg width="128" height="93" viewBox="0 0 128 93" fill="none">
                                                            <path d="M2 5C2 3.34315 3.34315 2 5 2H123C124.657 2 126 3.34315 126 5V82C126 83.6569 124.657 85 123 85H5C3.34315 85 2 83.6569 2 82V5Z" strokeWidth="4" />
                                                            <rect x="11" y="60" width="37" height="16" rx="2" />
                                                            <line x1="15" y1="91.5" x2="113" y2="91.5" strokeWidth="3" />
                                                        </svg>
                                                        <span></span>
                                                        Bottom-Left
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="" onClick={() => handlePositionSelection("TopRight")}>                                                
                                                <div className="fp-radio-btn">
                                                    <input type="radio" name="radio" checked={pageSetting?.position?.TopRight} readOnly />
                                                    <label>
                                                        <svg width="128" height="93" viewBox="0 0 128 93" fill="none">
                                                            <path d="M2 5C2 3.34315 3.34315 2 5 2H123C124.657 2 126 3.34315 126 5V82C126 83.6569 124.657 85 123 85H5C3.34315 85 2 83.6569 2 82V5Z" strokeWidth="4" />
                                                            <rect x="81" y="10" width="37" height="16" rx="2" />
                                                            <line x1="15" y1="91.5" x2="113" y2="91.5" strokeWidth="3" />
                                                        </svg>
                                                        <span></span>
                                                        Top-right
                                                    </label>
                                                </div>
                                            </li>
                                            <li onClick={() => handlePositionSelection("BottomRight")}>                                                
                                                <div className="fp-radio-btn">
                                                    <input type="radio" name="radio" checked={pageSetting?.position?.BottomRight} readOnly />

                                                    <label>
                                                        <svg width="128" height="93" viewBox="0 0 128 93" fill="none">
                                                            <path d="M2 5C2 3.34315 3.34315 2 5 2H123C124.657 2 126 3.34315 126 5V82C126 83.6569 124.657 85 123 85H5C3.34315 85 2 83.6569 2 82V5Z" strokeWidth="4" />
                                                            <rect x="81" y="60" width="37" height="16" rx="2" />
                                                            <line x1="15" y1="91.5" x2="113" y2="91.5" strokeWidth="3" />
                                                        </svg>
                                                        <span></span>
                                                        Bottom-Right
                                                    </label>
                                                </div>
                                            </li>
                                            <li onClick={() => handlePositionSelection("TopLeft")}>                                                
                                                <div className="fp-radio-btn">
                                                    <input type="radio" name="radio" checked={pageSetting?.position?.TopLeft} readOnly />

                                                    <label>
                                                        <svg width="128" height="93" viewBox="0 0 128 93" fill="none">
                                                            <path d="M2 5C2 3.34315 3.34315 2 5 2H123C124.657 2 126 3.34315 126 5V82C126 83.6569 124.657 85 123 85H5C3.34315 85 2 83.6569 2 82V5Z" strokeWidth="4" />
                                                            <rect x="11" y="10" width="37" height="16" rx="2" />
                                                            <line x1="15" y1="91.5" x2="113" y2="91.5" strokeWidth="3" />
                                                        </svg>
                                                        <span></span>
                                                        Top-Left
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Widget Position */}


                                <div className="fp-switch-wrapper mt-30">
                                    <div className="fp-switch-label">
                                        <input type="checkbox" id="widget-url" checked={pageSetting?.isWidgetUrl} onChange={(e) => setPageSetting({ ...pageSetting, isWidgetUrl: e.target.checked })} />
                                        <label htmlFor="widget-url"></label>
                                    </div>
                                    <p>Widget Redirect URL</p>
                                </div>

                                {pageSetting?.isWidgetUrl && <div className="fp-input-group fp-widget-url">
                                    <span>URL</span>
                                    <input type="text" placeholder="https://example.com/" value={pageSetting?.url || ""} onChange={(e) => setPageSetting({ ...pageSetting, url: e.target.value })} />
                                </div>}

                                <div className="fp-switch-wrapper mt-30">
                                    <div className="fp-switch-label">
                                        <input type="checkbox" id="mobile_alert" checked={pageSetting?.isMobileAlert} onChange={(e) => setPageSetting({ ...pageSetting, isMobileAlert: e.target.checked })} />
                                        <label htmlFor="mobile_alert"></label>
                                    </div>
                                    <p>Hide Alert On Mobile</p>
                                </div>

                               { !pageSetting?.isMobileAlert && <div className="fp-input-wrap mb-30 fp_multi_select_option mt-4">
                                    <label>Show Alerts On Mobile At</label>
                                    <AutoCompleteDropDown
                                        options={MobilePositions}
                                        defaultValue={pageSetting.mobileViewPosition}
                                        onChangeCallBAck={handleWidgetPosition}
                                    />
                                </div>}

                                <div className="fp-frequency-wrap">
                                    <h4 className="fp-subtitle"> Customise Alert Frequency</h4>
                                    <div className="fp-num-filed has-info">
                                        <label>
                                            Delay in alert display on page load
                                        </label>
                                        <div className="fp-num-unit fp-number">
                                            <input type="number" onKeyDown={handleKeyDown} value={pageSetting?.customiseAlertFrequency?.pageLoadAlertDelay|| 0} onChange={(e) => handleInputTime("pageLoadAlertDelay", e.target.value)} />
                                            <div className="numb-count-control">
                                                <span className="plus" onClick={(e) => handleOnChangeInput("pageLoadAlertDelay", "plus", pageSetting?.customiseAlertFrequency?.pageLoadAlertDelay)}></span>
                                                <span className="minus" onClick={(e) => handleOnChangeInput("pageLoadAlertDelay", "minus", pageSetting?.customiseAlertFrequency?.pageLoadAlertDelay)}></span>
                                            </div>
                                            <span>Seconds</span>
                                        </div>
                                    </div>

                                    <div className="fp-num-filed has-info">
                                        <label>
                                            Duration to display single alert
                                        </label>
                                        <div className="fp-num-unit fp-number">
                                            <input type="number" placeholder="4" onKeyDown={handleKeyDown} value={pageSetting?.customiseAlertFrequency?.singleAlertDuration|| 0} onChange={(e) => handleInputTime("singleAlertDuration", e.target.value)} />
                                            <div className="numb-count-control">
                                                <span className="plus" onClick={(e) => handleOnChangeInput("singleAlertDuration", "plus", pageSetting?.customiseAlertFrequency?.singleAlertDuration)}></span>
                                                <span className="minus" onClick={(e) => handleOnChangeInput("singleAlertDuration", "minus", pageSetting?.customiseAlertFrequency?.singleAlertDuration)}></span>
                                            </div>
                                            <span>Seconds</span>
                                        </div>
                                    </div>

                                    <div className="fp-num-filed has-info">
                                        <label>
                                            Delay between two alerts
                                        </label>
                                        <div className="fp-num-unit fp-number">
                                            <input type="number" placeholder="5" onKeyDown={handleKeyDown} value={pageSetting?.customiseAlertFrequency?.delayBetwwenTwoAlerts|| 0} onChange={(e) => handleInputTime("delayBetwwenTwoAlerts", e.target.value)} />
                                            <div className="numb-count-control">
                                                <span className="plus" onClick={(e) => handleOnChangeInput("delayBetwwenTwoAlerts", "plus", pageSetting?.customiseAlertFrequency?.delayBetwwenTwoAlerts)}></span>
                                                <span className="minus" onClick={(e) => handleOnChangeInput("delayBetwwenTwoAlerts", "minus", pageSetting?.customiseAlertFrequency?.delayBetwwenTwoAlerts)}></span>
                                            </div>
                                            <span>Seconds</span>
                                        </div>
                                    </div>
                                    <div className="fp-num-filed has-info">
                                        <label>
                                            Display alert when the count is equal to or greater than
                                            <div className="info-tooltip">
                                                <img src="https://img.flexifunnels.com/flexiproof-assets/info.svg" alt=""/>
                                                <span className="data">The widget alert will display only when the count is equal to or greater than the value set here.</span>
                                            </div>
                                        </label>
                                        <div>
                                        <div className="fp-num-unit fp-number">
                                            <input type="number" placeholder="10" onKeyDown={handleKeyDown}   value={pageSetting?.customiseAlertFrequency?.displayAlertCount||0} onChange={(e) => handleInputAlertTime("displayAlertCount", e.target.value, e)} />
                                            <div className="numb-count-control">
                                                <span className="plus" onClick={(e) => handleOnChangeAlertInput("displayAlertCount", "plus", pageSetting?.customiseAlertFrequency?.displayAlertCount)}></span>
                                                <span className="minus" onClick={(e) => handleOnChangeAlertInput("displayAlertCount", "minus", pageSetting?.customiseAlertFrequency?.displayAlertCount)}></span>
                                            </div>
                                            <span>Counts</span>
                                        </div>
                                        <div className="alert_note"> (Note - Max allowed count/second - 100)</div>
                                        </div>
                                       
                                    </div>
                                    <div className="has-info fpAlrFrmDrpdown">
                                        <label>
                                           Display the records from 
                                           <div className="info-tooltip ms-1">
                                                <img src="https://img.flexifunnels.com/flexiproof-assets/info.svg" alt=""/>
                                                <span className="data">Select the number of days for which you want to retrieve the records.</span>
                                            </div>
                                        </label>
                                        {/* <div className="fp-frm-wrapper"> */}
                                        <div className="fp-input-wrap mb-30 fp_multi_select_option">
                                            <select value={pageSetting?.customiseAlertFrequency?.showAlertsFrom || 0} onChange={(e) => handleDisplayCount(e.target.value)}>
                                                <option value=""> Select Type</option>
                                                {displayCounts?.map((display, index) => {
                                                    return <option key={`display_${index}`} value={display.value}>{display.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>

                                <div className="fp-btn-wrap fp-page-settings">
                                    <button className="fp-btn" onClick={() => savePageStting()}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}