import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from 'react';
import { useEffect } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function MultiSelectAutoComplete({ options, handleOnChange, defaultOptions, name,placeholder }) {

    const [defaultValue, setDefaultValue] = useState([])

    useEffect(() => {
        if (defaultOptions?.length == 0) {
            setDefaultValue([])
        } else {
            setDefaultValue(defaultOptions)
        }

    }, [defaultOptions])

    const showValue = (selected) => {
        return selected
            .map((value) => {
                const option = options?.find((option) => option.value == value.value);
                return option ? option.label : '';
            }).join(', ');
    }

    const handleChange = (event, value) => {
        handleOnChange(value)
    };

    return (
        <Autocomplete
            multiple
            id={"checkboxes-tags-demo" + name}
            options={options}
            onChange={handleChange}
            value={defaultValue}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label || ""}
            getOptionDisabled={(option) =>  !!option.used_mode 
              }

            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {/* {option.label} */}
                    <label htmlFor={option.value}>
                        {option.label}
                       { option?.path && <span className='ml-2 mx-2 fp_page_setting_span_clr'>{`Slug - (${option?.path}) `}</span>}
                       { option?.campaign_name && <span className='ml-2 fp_page_setting_span_clr'>{`Campaign - (${option?.campaign_name})`}</span>}
                      </label>
                </li>
            )}
            style={{ width: 500 }}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value && option.label === value.label
            }
            renderInput={(params) => (
                <TextField {...params}
                    placeholder={placeholder}
                />
            )}
            renderTags={(selected, getTagProps) =>
                <span>{showValue(selected)}</span>
            }
        />
    );
}

